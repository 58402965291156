import { Route, Routes } from 'react-router-dom';
import { LoginPage } from './LoginPage';
import { ResetPasswordChangePage } from './ResetPasswordChangePage';
import { ResetPasswordRequestPage } from './ResetPasswordRequestPage';

export function AuthRouter() {
  return (
    <Routes>
      <Route path="login" Component={LoginPage} />
      <Route path="reset-password" Component={ResetPasswordRequestPage} />
      <Route path="reset-password/:token" Component={ResetPasswordChangePage} />
    </Routes>
  );
}
