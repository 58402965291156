import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';

export const StakeholdersPage: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const tabPath = pathname.includes('listing') ? 'listing' : pathname.split('/').pop();
  const path = pathname.substring(0, pathname.lastIndexOf(`/${tabPath}`));

  return (
    <Layout>
      <Box
        sx={{
          display: 'flex',
          mx: '2rem',
        }}
      >
        <Typography alignSelf="center" variant="h1">
          <FormattedMessage id="stakeholders.title" />
        </Typography>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabPath}
          variant="scrollable"
          scrollButtons="auto"
          aria-label={intl.formatMessage({ id: 'stakeholders.tabs' })}
        >
          <Tab
            value="listing"
            label={intl.formatMessage({ id: 'stakeholders.listing.title' })}
            href={`${path}/listing`}
          />
          <Tab
            value="matching"
            label={intl.formatMessage({ id: 'stakeholders.matching.title' })}
            href={`${path}/matching`}
          />
          <Tab value="label" label={intl.formatMessage({ id: 'stakeholders.label.title' })} href={`${path}/label`} />
        </Tabs>
      </Box>
      <Container role="tabpanel" sx={{ mt: '2rem' }}>
        {children}
      </Container>
    </Layout>
  );
};
