import { ProtectedRoute } from '@shared/src/components/ProtectedRoute';
import { Provider } from '@shared/src/components/providers/ProjetProvider';
import { PROJET_GLOBAL_ID } from '@shared/src/constant/global-constants';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { BeforeFetchEvent, addEventListener, removeEventListener } from '@shared/src/services/HttpService';
import { useCallback, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ExplanationGlobal } from '../../components/Questionnaire/ExplanationGlobal';
import { QuestionsGlobal } from '../../components/Questionnaire/QuestionsGlobal';
import { SubjectsGlobal } from '../../components/Questionnaire/SubjectsGlobal';
import { SubsubjectsGlobal } from '../../components/Questionnaire/SubsubjectsGlobal';
import { MatchingGlobal } from '../../components/Stakeholders/MatchingGlobal';
import { StakeholdersLabelGlobal } from '../../components/Stakeholders/StakeholdersLabelGlobal';

export function GlobalSettingsRouter() {
  const setupProjetId = useCallback(function onBeforeFetch(event: BeforeFetchEvent) {
    const url = new URL(event.url);
    url.searchParams.set('projetId', PROJET_GLOBAL_ID);
    event.url = url.toString();
  }, []);

  useEffect(() => {
    addEventListener('before:fetch', setupProjetId);
    return function () {
      removeEventListener('before:fetch', setupProjetId);
    };
  }, [setupProjetId]);

  return (
    <Provider projetId={PROJET_GLOBAL_ID}>
      <Routes>
        <Route element={<ProtectedRoute role={[UtilisateurRole.TENZING]} />}>
          <Route path="/" element={<Navigate to="sujet" />} />
          <Route path="/sujet/*" Component={SubjectsGlobal} />
          <Route path="/question/*" Component={QuestionsGlobal} />
          <Route path="/sous-sujet/*" Component={SubsubjectsGlobal} />
          <Route path="/label/*" Component={StakeholdersLabelGlobal} />
          <Route path="/matching/*" Component={MatchingGlobal} />
          <Route path="/explication/*" Component={ExplanationGlobal} />
        </Route>
      </Routes>
    </Provider>
  );
}
