import { Container, ContainerProps, Stack, Theme } from '@mui/material';
import { SystemCssProperties } from '@mui/system';
import React, { PropsWithChildren } from 'react';
import { Footer } from './Footer';
import HeaderLayout from './HeaderLayout';

type Props = {
  backgroundColor?: SystemCssProperties<Theme>['backgroundColor'];
  maxWidth?: ContainerProps['maxWidth'];
  connected?: boolean;
};
const Layout: React.FC<PropsWithChildren<Props>> = ({
  maxWidth = 'xl',
  children,
  connected,
  backgroundColor = 'background.default',
}) => (
  <Stack sx={{ display: 'flex', flexDirection: 'column', overflowY: 'hidden', backgroundColor: backgroundColor }}>
    <Stack
      direction="column"
      sx={{
        zIndex: 2,
        minHeight: '100vh',
      }}
    >
      {<HeaderLayout connected={connected} />}
      <Container
        component="main"
        sx={{
          textAlign: 'center',
          my: '2rem',
          display: 'flex',
          alignItems: 'stretch',
          flexDirection: 'column',
          pl: { md: '12rem' }, // pour le menu
        }}
        maxWidth={maxWidth}
        disableGutters
      >
        {children}
      </Container>
      <Footer />
    </Stack>
  </Stack>
);

export default Layout;
