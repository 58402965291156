import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { CategoriePartiePrenanteDto } from '@shared/src/api/categorie-partie-prenante/dto/categorie-partie-prenante.dto';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { useUtilisateur } from '@shared/src/components/providers/UtilisateurProvider';
import { StatutProjet } from '@shared/src/enum/projet.enum';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { categoriePartiePrenantesService } from '@shared/src/services/CategoriePartiePrenantesService';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

export function StakeholdersLabelTab() {
  const { projetId } = useParams();
  const { projet } = useProjet();
  const { utilisateur, isLoading } = useUtilisateur();
  const intl = useIntl();
  const [categories, setCategories] = useState<CategoriePartiePrenanteDto[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  useEffect(() => {
    categoriePartiePrenantesService
      .findAll()
      .then(function (response) {
        if ('statusCode' in response) {
          return Promise.reject(response);
        }
        setCategories(response.data);
      })
      .catch(function (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      });
  }, [projetId]);

  const updateSubjects = (e: React.FormEvent) => {
    e.preventDefault();
    categories.forEach((categorie) => {
      categoriePartiePrenantesService.update(categorie.id, { nom: categorie.nom });
    });
    setSnackbarMessage(intl.formatMessage({ id: 'stakeholders.label.update' }));
    setOpenSnackbar(true);
  };

  if (isLoading) {
    return null;
  }

  if (utilisateur?.role === UtilisateurRole.TENZING && projet?.statut === StatutProjet.CREE) {
    return (
      <Stack component="form" onSubmit={updateSubjects}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            setOpenSnackbar(false);
            setSnackbarMessage('');
          }}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Paper sx={{ mb: '2rem' }}>
          <FormControl sx={{ width: '100%' }}>
            {categories.map((categorie) => (
              <Grid container key={categorie.id} spacing={2} sx={{ p: '2rem' }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id={`${categorie.id}-name-fr`}
                    label={intl.formatMessage({ id: 'questionnaire.name.fr' })}
                    defaultValue={categorie.nom.fr ?? ''}
                    required
                    autoComplete="no"
                    onChange={(e) => {
                      categorie.nom.fr = e.target.value;
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id={`${categorie.id}-name-en`}
                    label={intl.formatMessage({ id: 'questionnaire.name.en' })}
                    defaultValue={categorie.nom.en ?? ''}
                    required
                    autoComplete="no"
                    onChange={(e) => {
                      categorie.nom.en = e.target.value;
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
            ))}
          </FormControl>
        </Paper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button type="submit" variant="contained" sx={{ width: '15rem' }}>
            {intl.formatMessage({ id: 'questionnaire.save' })}
          </Button>
        </Box>
      </Stack>
    );
  } else {
    return (
      <TableContainer component={Paper} sx={{ borderRadius: '1rem', padding: '1rem', maxWidth: '658px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'questionnaire.name.fr' })}
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'questionnaire.name.en' })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((categorie) => (
              <TableRow key={categorie.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" width="50%">
                  {categorie.nom.fr || '/'}
                </TableCell>
                <TableCell component="th" align="left">
                  {categorie.nom.en || '/'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
