import { ThemeProvider } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import createTheme from '@mui/material/styles/createTheme';
import { ProtectedRoute } from '@shared/src/components/ProtectedRoute';
import ScrollToTop from '@shared/src/components/ScrollToTop';
import { Provider as UtilisateurProvider } from '@shared/src/components/providers/UtilisateurProvider';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { themes } from '@shared/theme';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthRouter } from './pages/auth';
import { GlobalSettingsRouter } from './pages/global-settings';
import { HomePage } from './pages/home';
import { CookiePolicyPage } from './pages/legal/CookiePolicyPage';
import { LegalInformationPage } from './pages/legal/LegalInformationPage';
import { PrivacyPolicyPage } from './pages/legal/PrivacyPolicyPage';
import { NotFoundPage } from './pages/not-found';
import { ProjetsRouter } from './pages/projets';
import { OrganisationPage } from './pages/projets/OrganisationPage';
import { SettingsPage } from './pages/projets/SettingsPage';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = prefersDarkMode ? 'backofficeDark' : 'backofficeLight';
  const currentTheme = createTheme(themes[theme]);

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <UtilisateurProvider>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route element={<ProtectedRoute role={[UtilisateurRole.TENZING, UtilisateurRole.CLIENT]} />}>
                <Route path="/" Component={HomePage} />
              </Route>
              <Route path="/auth/*" Component={AuthRouter} />
              <Route path="/legal-information" Component={LegalInformationPage} />
              <Route path="/privacy-policy" Component={PrivacyPolicyPage} />
              <Route path="/cookie-policy" Component={CookiePolicyPage} />
              <Route path="/global-settings/*" Component={GlobalSettingsRouter} />
              <Route path="/:organisationId/projets/:projetId/*" Component={ProjetsRouter} />
              <Route element={<ProtectedRoute role={[UtilisateurRole.TENZING]} />}>
                <Route path="/organisation/create" Component={OrganisationPage} />
                <Route path="/:organisationId/edit" Component={OrganisationPage} />
                <Route path="/:organisationId/projet/create" Component={SettingsPage} />
              </Route>
              <Route path="*" Component={NotFoundPage} />
            </Routes>
          </BrowserRouter>
        </UtilisateurProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
