import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, FormLabel } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SwitchFormField } from '@shared/src/components/form/SwitchFormField';
import {
  SwitchFormFieldOption,
  SwitchFormFieldPropsEvent,
  SwitchFormFieldPropsEventHandler,
} from '@shared/src/components/form/SwitchFormFieldTypes';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export type AccordionFilterProps = {
  panel_name: string;
  panel_key: string;
  panel_header_label_id: string;
  switch_options: SwitchFormFieldOption[];
  onChange: SwitchFormFieldPropsEventHandler;
  expandedPanel: string | false;
  setExpandedPanel: any;
  selectedPanel?: boolean;
};

export function AccordionFilterElement(props: AccordionFilterProps) {
  const {
    panel_name,
    panel_key,
    panel_header_label_id,
    switch_options,
    onChange,
    expandedPanel,
    setExpandedPanel,
    selectedPanel,
  } = props;

  const handleExpandedPanelChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  const onChangeSwitchForm = useCallback(
    (event: SwitchFormFieldPropsEvent) => {
      onChange({
        id: event.id,
        checked: event.checked,
      });
    },
    [onChange],
  );

  return (
    <Accordion
      sx={{
        border: selectedPanel ? 3 : 0,
        borderColor: 'primary.main',
      }}
      expanded={expandedPanel === panel_name}
      onChange={handleExpandedPanelChange(panel_name)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`${panel_name}-header`}>
        <Typography>
          <FormLabel id={panel_key}>
            <FormattedMessage id={panel_header_label_id} />
          </FormLabel>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SwitchFormField name={panel_key} onChange={onChangeSwitchForm} options={switch_options} />
      </AccordionDetails>
    </Accordion>
  );
}
