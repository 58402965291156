import { Box, Typography } from '@mui/material';
import { OrganisationDto } from '@shared/src/api/organisation/dto/organisation.dto';
import { NoLogo } from '@shared/src/components/NoLogo';
import { useUtilisateur } from '@shared/src/components/providers/UtilisateurProvider';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { FormattedMessage } from 'react-intl';

export function OrganisationBanner(props: { organisation: OrganisationDto }) {
  const { utilisateur, isLoading } = useUtilisateur();

  if (isLoading) {
    return null;
  }
  return (
    <Box
      sx={{
        display: {
          xs: 'none',
          md: 'flex',
        },
        alignItems: 'center',
        position: 'absolute',
        right: 0,
        borderBottomLeftRadius: '1rem',
        backgroundColor: 'secondary.dark',
        px: '1rem',
      }}
    >
      {utilisateur?.role === UtilisateurRole.TENZING && (
        <Typography variant="body1">
          <FormattedMessage id="organisation.banner" />
        </Typography>
      )}
      <Typography variant="body1" ml="0.5rem" fontWeight={600}>
        {props.organisation.nom}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minHeight: 50,
          maxHeight: 50,
          marginY: 0.5,
          marginLeft: 2,
        }}
      >
        {props.organisation.logo && props.organisation.logo.length > 0 && (
          <img src={`${props.organisation.logo}`} alt={`logo-${props.organisation.nom}`} height={50} />
        )}
        {(!props.organisation.logo || props.organisation.logo.length === 0) && <NoLogo size={'small'} />}
      </Box>
    </Box>
  );
}
