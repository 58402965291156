import { Box, Grid, Stack, Typography } from '@mui/material';
import {
  MatrixDto,
  MatrixItem,
  MatrixItemWithLabelDetail,
  MatrixLabelDetail,
  MatrixLabelPosition,
} from '@shared/src/api/matrix/dto/matrix.dto';
import { ScatterPointShape } from '@shared/src/api/pillier/dto/pillier.dto';
import { blues, greens, pinks } from '@shared/src/assets/colors/Colors';
import { useLang } from '@shared/src/components/providers/LangProvider';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CartesianGrid,
  LabelList,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  adjustPointPositions,
  adjustPointsWithMargin,
  calculateTopLabelY,
  getLabelPosition,
  splitStringEquallyIn2Lines,
} from '../../services/MatrixService';

const defaultTopMargin = 60;
const maxDataX = 4;

const chartHeight = 800;

// Graph SVG coordinates
const minSvgX = 100;
const minSvgY = defaultTopMargin;
const maxSvgY = 0.8 * chartHeight;

// SVG abscissa range corresponding to 1 data unit
const svgXRatio = 0.75 / maxDataX;

const CustomTooltip = (props: any) => {
  const { lang } = useLang();
  if (props.payload?.length) {
    return (
      <Box className="custom-tooltip" sx={{ fontSize: '12px', backgroundColor: 'white', py: 0.1, px: 1 }}>
        <p>{props.payload[0].payload.label[lang]}</p>
        <p className="label">{`${props.payload[0].name} : ${+parseFloat(
          props.payload[0].payload.outsideIn.toFixed(1),
        )}`}</p>
        <p className="label">{`${props.payload[1].name} : ${+parseFloat(
          props.payload[1].payload.insideOut.toFixed(1),
        )}`}</p>
      </Box>
    );
  }

  return null;
};

const GetTickLabel = (value: number) => {
  const intl = useIntl();
  const authorizedTickValues: number[] = [1, 2, 3, 4];
  return authorizedTickValues.includes(value) ? intl.formatMessage({ id: `matrix.chart.tick${value}` }) : '';
};

const CustomizedXAxisTick = (props: any) => {
  const { x, y, payload } = props;
  const tickLabel: string = GetTickLabel(payload.value);
  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={7} textAnchor="middle" fill="#666" fontSize="11px">
        {tickLabel}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = (props: any) => {
  const { x, y, payload } = props;
  const tickLabel: string = GetTickLabel(payload.value);
  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={-7} textAnchor="middle" fill="#666" fontSize="11px" transform="rotate(-90)">
        {tickLabel}
      </text>
    </g>
  );
};

type LegendElementProps = { shape: ScatterPointShape; name: string; color: string };

const LegendElement = ({ shape, color, name }: LegendElementProps) => (
  <ul className="recharts-default-legend" style={{ padding: '0px', margin: '0px', textAlign: 'left' }}>
    <li style={{ display: 'block', marginRight: '10px' }}>
      <svg
        width="32"
        height="16"
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginRight: '4px',
        }}
      >
        {shape === 'triangle' && <polygon points="0,16 8,0 16,16" fill={color} />}
        {shape === 'circle' && <circle cx="22" cy="8" r="8" fill={color} />}
        {shape === 'square' && <rect x="0" y="0" width="16" height="16" fill={color} />}
      </svg>
      <span>{name}</span>
    </li>
  </ul>
);

const renderLegend = (props: any) => {
  const { payload } = props;

  // Add colors of piliers to the list of subjects' colors
  const greensColors = greens;
  const bluesColors = blues;
  const pinksColors = pinks;
  greensColors.push('green');
  bluesColors.push('blue');
  pinksColors.push('#ff477e');

  // Create one list for each pilier
  const greenList: { color: string; name: string; order: number; shape: ScatterPointShape }[] = [];
  const blueList: { color: string; name: string; order: number; shape: ScatterPointShape }[] = [];
  const pinkList: { color: string; name: string; order: number; shape: ScatterPointShape }[] = [];

  payload.forEach((el: any) => {
    const green = greensColors.findIndex((item) => item === el.color);
    const blue = bluesColors.findIndex((item) => item === el.color);
    const pink = pinksColors.findIndex((item) => item === el.color);
    if (green > -1) {
      greenList.push({ color: el.color, name: el.payload.name, order: green, shape: 'triangle' });
    }
    if (blue > -1) {
      blueList.push({ color: el.color, name: el.payload.name, order: blue, shape: 'square' });
    }
    if (pink > -1) {
      pinkList.push({ color: el.color, name: el.payload.name, order: pink, shape: 'circle' });
    }
  });

  // Sort list element by color intensity
  greenList.sort((a, b) => b.order - a.order);
  pinkList.sort((a, b) => b.order - a.order);
  blueList.sort((a, b) => b.order - a.order);

  const todayDate = new Date().toLocaleDateString();

  return (
    <Stack>
      <Grid container spacing={2} width="900px">
        <Grid item xs={4}>
          {greenList.map(({ color, name, shape }, index: number) => (
            <LegendElement key={`item-environnement-${index}`} name={name} shape={shape} color={color} />
          ))}
        </Grid>

        <Grid item xs={4}>
          {pinkList.map(({ color, name, shape }, index: number) => (
            <LegendElement key={`item-social-${index}`} name={name} shape={shape} color={color} />
          ))}
        </Grid>

        <Grid item xs={4}>
          {blueList.map(({ color, name, shape }, index: number) => (
            <LegendElement key={`item-governance-${index}`} name={name} shape={shape} color={color} />
          ))}
        </Grid>
      </Grid>
      <Typography variant="body2" marginTop="2rem" textAlign="left" fontStyle="italic">
        <FormattedMessage id="matrix.date" /> {todayDate}
      </Typography>
    </Stack>
  );
};

export function Matrix(props: MatrixDto) {
  const { items, pilliers } = props;
  const intl = useIntl();
  const { lang } = useLang();
  const [windowWidth] = useState(window.innerWidth);
  // points avec positions recalculées pour éviter le chevauchement des points
  const [points, setPoints] = useState<MatrixItemWithLabelDetail[]>([]);
  const [additionalTopMargin, setAdditionalTopMargin] = useState<number>(0);

  const itemsByPillier = (pillierId: string) => {
    return points.filter((item) => item.pillierId === pillierId);
  };

  /**
   * Converts data abscissa to SVG abscissa
   */
  const xConvertToSvg = useCallback((dataX: number) => minSvgX + dataX * windowWidth * svgXRatio, [windowWidth]);

  /**
   * Converts data ordinate to SVG ordinate
   */
  const yConvertToSvg = useCallback((dataY: number) => maxSvgY - (dataY * (maxSvgY - minSvgY)) / maxDataX, []);

  const adjustOnBordures = useCallback(
    (adjustedPoint: { x: number; y: number }) => {
      const xMax = xConvertToSvg(maxDataX);

      if (adjustedPoint.x <= minSvgX) {
        // label sur l'axe des ordonnées => on décale
        adjustedPoint.x += 40;
      } else if (adjustedPoint.x >= xMax) {
        // label sur la bordure de droite => on décale
        adjustedPoint.x -= 45;
      }
      return adjustedPoint;
    },
    [xConvertToSvg],
  );

  const buildLabelCoords = useCallback(
    (points: MatrixItem[]): MatrixItemWithLabelDetail[] => {
      const labelPositions: MatrixLabelPosition[] = [];

      return points.map((item) => {
        const { label, x, y } = item;
        const labelValue = label[lang] ?? '';

        const graphX = xConvertToSvg(x);
        const graphY = yConvertToSvg(y);

        const lines = splitStringEquallyIn2Lines(labelValue);
        let lineWidthMax = 0;
        let lineHeigtMax = 15;
        for (let i = 0; i < lines.length; i++) {
          const lineWidth = lines[i].length * 10;
          if (lineWidth > lineWidthMax) lineWidthMax = lineWidth;
          if (i === 1) {
            lineHeigtMax = 30;
          }
        }

        const { x: labelX, y: labelY } = adjustOnBordures({ x: graphX, y: graphY - 2 * lineHeigtMax });

        const { x: newX, y: newY } = getLabelPosition(
          labelPositions,
          labelX,
          labelY,
          labelValue,
          lineWidthMax,
          lineHeigtMax,
        );
        labelPositions.push({ labelValue, x: newX, y: newY, lineHeigtMax, lineWidthMax });

        return { ...item, labelDetail: { label: item.label, labelX: newX, labelY: newY, graphX, graphY, lines } };
      });
    },
    [lang, adjustOnBordures, xConvertToSvg, yConvertToSvg],
  );

  useEffect(
    function () {
      const adjustedPoints = adjustPointPositions(items, maxDataX);
      const pointsWithLabelCoords = buildLabelCoords(adjustedPoints);
      const topLabelY = calculateTopLabelY(pointsWithLabelCoords);
      const pointsWithMargin = adjustPointsWithMargin(pointsWithLabelCoords, topLabelY);
      setPoints(pointsWithMargin);
      setAdditionalTopMargin(-topLabelY);
    },
    [buildLabelCoords, items],
  );

  /**
   * The type of this function should be the rechart's type "ContentType". However, the type forces the type of the attribute "value" of "props" to be string | number | undefined.
   * But at runtime, "value" is a MatrixLabelDetail.
   *
   * This is why the type of "props" is set to "any" and why value is casted to MatrixLabelDetail.
   */
  const renderCustomizedLabel = (props: any) => {
    const { x, y, value, width, fill } = props;
    const { labelX: calcX, labelY: calcY, lines } = value as MatrixLabelDetail;

    const tailleDemiPoint = width / 2;
    const labelX = calcX + tailleDemiPoint;
    const labelY = calcY - width;

    return (
      <g className="matrix-label">
        <line
          x1={x + tailleDemiPoint}
          y1={y + tailleDemiPoint}
          x2={labelX + tailleDemiPoint}
          y2={labelY - width + 17 * lines.length} // lineHeight (15) + 2
          strokeWidth="1"
          stroke={fill}
          strokeDasharray="3,3"
        />
        <text x={labelX} y={labelY} width={width} textAnchor="middle" dominantBaseline="middle" fontSize={12}>
          {lines.map((line: string, index) => (
            <tspan key={index} x={labelX} dy="1em">
              {line}
            </tspan>
          ))}
        </text>
      </g>
    );
  };

  return (
    <Stack id="matrix-and-legend" style={{ paddingBottom: '3rem' }}>
      <ResponsiveContainer width="100%" height={chartHeight + additionalTopMargin}>
        <ScatterChart
          width={1200}
          margin={{
            top: defaultTopMargin + additionalTopMargin,
            right: 70,
            bottom: 10,
            left: 40,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            domain={[0, maxDataX]}
            tickCount={5}
            tick={<CustomizedXAxisTick />}
            tickMargin={5}
            tickSize={5}
            dataKey="x"
            type="number"
            name="Outside in"
            axisLine={{ stroke: '#A57B48' }}
            label={{
              value: intl.formatMessage({ id: 'matrix.chart.xaxis' }),
              position: 'insideBottomRight',
              offset: '-5',
              dy: 10,
              fontWeight: '800',
            }}
          />
          <YAxis
            domain={[0, maxDataX]}
            tickCount={5}
            tick={<CustomizedYAxisTick />}
            tickSize={5}
            dataKey="y"
            type="number"
            name="Inside out"
            axisLine={{ stroke: '#A57B48' }}
            label={{
              value: intl.formatMessage({ id: 'matrix.chart.yaxis' }),
              angle: -90,
              position: 'top',
              offset: '-90',
              dx: -15,
              fontWeight: '800',
            }}
          />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />} />
          <ReferenceArea x1={2} x2={maxDataX} y1={maxDataX / 2} y2={maxDataX} fill="#A57B48" fillOpacity={0.35} />
          <ReferenceArea x1={0} x2={maxDataX / 2} y1={maxDataX / 2} y2={maxDataX} fill="#A57B48" fillOpacity={0.2} />
          <ReferenceArea x1={2} x2={maxDataX} y1={0} y2={maxDataX / 2} fill="#A57B48" fillOpacity={0.2} />
          <Legend
            layout="vertical"
            content={renderLegend}
            wrapperStyle={{ fontSize: '12px', position: 'absolute', bottom: '-2rem', left: '100px' }}
          />
          {pilliers.map((pillier) => (
            <Scatter
              key={pillier.id}
              name={pillier.nom[lang] ?? ''}
              data={itemsByPillier(pillier.id)}
              fill={pillier.couleur}
              shape={pillier?.shape ?? 'star'}
            >
              <LabelList dataKey="labelDetail" position="top" fontSize={'0.7rem'} content={renderCustomizedLabel} />
            </Scatter>
          ))}
        </ScatterChart>
      </ResponsiveContainer>
    </Stack>
  );
}
