import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { ProjetDto } from '@shared/src/api/projet/dto/projet.dto';
import { ReactComponent as LogoTenzing } from '@shared/src/assets/svg/logo-tenzing';
import LanguageDropdown from '@shared/src/components/LanguageDropdown';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { useUtilisateur } from '@shared/src/components/providers/UtilisateurProvider';
import { StatutProjet } from '@shared/src/enum/projet.enum';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { organisationsService } from '@shared/src/services/OrganisationsService';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

type DrawerProps = {
  connected: boolean;
  setAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DrawerContent: React.FC<PropsWithChildren<DrawerProps>> = ({ connected, setAlertOpen }) => {
  const intl = useIntl();
  const [projects, setProjects] = useState<ProjetDto[]>([]);
  const { organisation, projet } = useProjet();
  const { utilisateur } = useUtilisateur();
  const { pathname } = useLocation();

  const menuTopOrganisation = [
    {
      icon: utilisateur?.role === UtilisateurRole.TENZING && <SettingsOutlinedIcon sx={{ mr: '0.5rem' }} />,
      title: 'global-settings',
    },
  ];

  const menuTopProjet = [
    {
      icon: <GridViewOutlinedIcon sx={{ mr: '0.5rem' }} />,
      title: 'dashboard',
    },
    {
      icon: <GroupOutlinedIcon sx={{ mr: '0.5rem' }} />,
      title: 'stakeholders',
    },
    {
      icon: <TrendingUpOutlinedIcon sx={{ mr: '0.5rem' }} />,
      title: 'matrix',
    },
    {
      icon: <TaskAltOutlinedIcon sx={{ mr: '0.5rem' }} />,
      title: 'questionnaire',
    },
    {
      icon: utilisateur?.role === UtilisateurRole.TENZING && <SettingsOutlinedIcon sx={{ mr: '0.5rem' }} />,
      title: 'settings',
    },
  ].filter((m) => m.icon);

  useEffect(
    function () {
      const abortController = new AbortController();
      if (organisation)
        organisationsService
          .findOne(organisation.id)
          .then(function (response) {
            if ('statusCode' in response) {
              return Promise.reject(response);
            }
            setProjects(response.data?.projets ?? []);
          })
          .catch(function (error) {
            if (error.name === 'AbortError') return;
            console.error(error);
          });
      return function () {
        abortController.abort();
      };
    },
    [organisation],
  );

  return (
    <Box sx={{ mx: '1rem', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Grid container display="flex" flexDirection="column" alignItems="center">
        <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box
            component={Link}
            to={connected ? '/' : '/auth/login'}
            sx={{
              minWidth: 90,
              minHeight: 90,
              maxWidth: 90,
              maxHeight: 90,
            }}
          >
            <LogoTenzing />
          </Box>
        </Grid>
        {connected && organisation && (
          <>
            <FormControl sx={{ mt: '1rem', width: '100%' }}>
              <InputLabel id="project-choice-label">
                {intl.formatMessage({ id: 'header.menu.projects-select' })}
              </InputLabel>
              <Select
                labelId="project-choice-label"
                id="subject-choice"
                value={projet?.id ?? ''}
                label={intl.formatMessage({ id: 'header.menu.projects-select' })}
                onChange={(e) => {
                  window.location.href = `/${organisation.id}/projets/${e.target.value}/dashboard`;
                }}
              >
                {projects.map((project) => (
                  <MenuItem key={project.nom} value={project.id}>
                    {project?.statut === StatutProjet.CLOS && intl.formatMessage({ id: 'projet.closeProjet.tag' })}
                    {project.nom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {utilisateur?.role === UtilisateurRole.TENZING && (
              <Button
                variant="outlined"
                sx={{ width: '100%', mt: '1rem', p: '0.5rem' }}
                component={Link}
                to={`/${organisation.id}/projet/create`}
                startIcon={<AddCircleOutlineOutlinedIcon />}
              >
                {intl.formatMessage({ id: 'header.menu.add-project' })}
              </Button>
            )}
          </>
        )}
        {connected &&
          !organisation &&
          !projet &&
          utilisateur?.role === UtilisateurRole.TENZING &&
          menuTopOrganisation.map(({ icon, title }) => (
            <Grid
              key={intl.formatMessage({ id: `header.menu.${title}` })}
              component={Link}
              to={`/${title}`}
              item
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                py: '1rem',
                textDecoration: 'none',
                color: pathname.includes(title) ? 'primary.main' : 'text.primary',
                ':hover': { textDecoration: 'underline', color: 'primary.main' },
              }}
            >
              {icon}
              {intl.formatMessage({ id: `header.menu.${title}` })}
            </Grid>
          ))}
        {connected &&
          organisation &&
          projet &&
          menuTopProjet.map(({ icon, title }) => (
            <Grid
              key={intl.formatMessage({ id: `header.menu.${title}` })}
              component={Link}
              to={projet?.id ? `/${organisation.id}/projets/${projet.id}/${title}` : '/'}
              item
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                py: '1rem',
                textDecoration: 'none',
                color: pathname.includes(title) ? 'primary.main' : 'text.primary',
                ':hover': { textDecoration: 'underline', color: 'primary.main' },
              }}
            >
              {icon}
              {intl.formatMessage({ id: `header.menu.${title}` })}
            </Grid>
          ))}
      </Grid>
      <Grid container display="flex" flexDirection="column" alignItems="center">
        <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', py: '1rem', width: '100%' }}>
          <LanguageDropdown />
        </Grid>
        {connected && (
          <Grid
            onClick={() => setAlertOpen(true)}
            item
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              py: '1rem',
              textAlign: 'center',
              color: 'text.primary',
              ':hover': { textDecoration: 'underline', color: 'primary.main' },
            }}
          >
            <LogoutIcon sx={{ mr: '0.5rem' }} />
            <FormattedMessage id="header.logout-button" />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
