import { ProtectedRoute } from '@shared/src/components/ProtectedRoute';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { Route, Routes } from 'react-router-dom';
import { StakeholdersPage } from '../../pages/projets/StakeholdersPage';
import { QuestionnaireAnswerAsRouter } from './QuestionnaireAnswerAs';
import { StakeholdersListingTab } from './StakeholdersListingTab';

export function StakeholdersListingPage() {
  return (
    <StakeholdersPage>
      <Routes>
        <Route element={<ProtectedRoute role={[UtilisateurRole.TENZING, UtilisateurRole.CLIENT]} />}>
          <Route path="/" Component={StakeholdersListingTab} />
          <Route path="/:partiePrenanteId/*" Component={QuestionnaireAnswerAsRouter} />
        </Route>
      </Routes>
    </StakeholdersPage>
  );
}
