import { ProtectedRoute } from '@shared/src/components/ProtectedRoute';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { Route, Routes } from 'react-router-dom';
import { QuestionnairePage } from '../../pages/projets/QuestionnairePage';
import { QuestionnairePreviewRouter } from './QuestionnairePreview';
import { ChoicePage } from './QuestionnairePreview/ChoicePage';

export function PreviewTab() {
  return (
    <QuestionnairePage>
      <Routes>
        <Route element={<ProtectedRoute role={[UtilisateurRole.TENZING, UtilisateurRole.CLIENT]} />}>
          <Route path="/" Component={ChoicePage} />
          <Route path="/:categorieId/*" Component={QuestionnairePreviewRouter} />
        </Route>
      </Routes>
    </QuestionnairePage>
  );
}
