import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import {
  ReponseSummaryDto,
  SujetsWithNbRespondents,
} from '@shared/src/api/reponse-partie-prenante/dto/reponse-partie-prenante.dto';
import { useUtilisateur } from '@shared/src/components/providers/UtilisateurProvider';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { useAllSujets } from '@shared/src/hooks/useAllSujets';
import { reponsesService } from '@shared/src/services/ReponsesService';
import { ServerResponse } from '@shared/src/services/ServerResponse';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { FakeLogoTenzing } from '../../components/Dashboard/FakeLogoTenzing';
import { StatisticBlock } from '../../components/Dashboard/StatisticBlock';
import { SubjectsList } from '../../components/Dashboard/SubjectsList';
import Layout from '../../components/Layout/Layout';

export function DashboardPage() {
  const navigation = useNavigate();
  const { utilisateur } = useUtilisateur();
  const intl = useIntl();
  const { organisationId, projetId } = useParams();
  const { sujets } = useAllSujets(projetId);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sujetsWithNbRespondents, setSujetsWithNbRespondents] = useState<SujetsWithNbRespondents[]>([]);
  const [nbPPApproached, setNbPPApproached] = useState<number>(0);
  const [percentageOfRespondents, setPercentageOfRespondents] = useState<number>(0);
  const [nbQuestionnairesInProgress, setNbQuestionnairesInProgress] = useState<number>(0);

  useEffect(
    function () {
      if (!projetId) {
        setSujetsWithNbRespondents([]);
        setNbPPApproached(0);
        setPercentageOfRespondents(0);
        setNbQuestionnairesInProgress(0);
        setIsLoading(false);
        return;
      }

      if (sujets) {
        reponsesService.getReponseSummary().then((res: ServerResponse<ReponseSummaryDto>) => {
          if ('data' in res) {
            const initialSujetsWithNbRespondents = sujets.map((el) => {
              return {
                sujet_id: el.id,
                sujet_nom: { fr: el.nom.fr ?? '', en: el.nom.en ?? '' },
                nbRespondents:
                  res.data.sujetsWithNbRespondents.find((sujet) => sujet.sujet_id === el.id)?.nbRespondents ?? 0,
              };
            });
            setSujetsWithNbRespondents(initialSujetsWithNbRespondents);
            setNbPPApproached(res.data.nbPPApproached);
            setPercentageOfRespondents(res.data.percentageOfRespondents);
            setNbQuestionnairesInProgress(res.data.nbQuestionnairesInProgress);
            setIsLoading(false);
          }
        });
      }
    },
    [projetId, sujets],
  );

  return (
    <Layout maxWidth={false}>
      {utilisateur?.role === UtilisateurRole.TENZING && (
        <Stack width="95%" alignSelf="center" marginBottom="32px">
          <Button
            variant="text"
            sx={{
              maxWidth: '260px',
              minWidth: '240px',
              height: '22px',
              color: 'text.primary',
              justifyContent: 'left',
            }}
            startIcon={<ArrowBackIcon />}
            onClick={() => navigation('/')}
          >
            {intl.formatMessage({ id: 'dashboard.button.goBackToClientList' })}
          </Button>
        </Stack>
      )}
      <Stack width="95%" alignSelf="center" display="flex" flexDirection="row" justifyContent="space-between">
        <Stack width="100%">
          <Grid
            container
            alignItems="center"
            marginBottom={2}
            columnSpacing={4}
            sx={{ width: { xs: '100%', md: '90%', lg: '652px' } }}
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h2" color="primary.main" align="left">
                {intl.formatMessage({ id: 'dashboard.header.stakeholders' })}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'right' }}>
              <Button
                variant="text"
                sx={{
                  height: '22px',
                  justifyContent: 'right',
                }}
                endIcon={<ArrowForwardIcon />}
                onClick={() => navigation(`/${organisationId}/projets/${projetId}/stakeholders/listing`)}
              >
                {intl.formatMessage({ id: 'dashboard.button.goToStakeholdersList' })}
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, width: '100%' }}>
            <StatisticBlock
              isLoading={isLoading}
              keyValue={`${nbPPApproached}`}
              legend={intl.formatMessage({ id: 'dashboard.statistics.nbPPApproached' })}
            />
            <StatisticBlock
              isLoading={isLoading}
              keyValue={`${percentageOfRespondents}%`}
              legend={intl.formatMessage({ id: 'dashboard.statistics.percentageOfRespondents' })}
            />
            <StatisticBlock
              isLoading={isLoading}
              keyValue={`${nbQuestionnairesInProgress}`}
              legend={intl.formatMessage({ id: 'dashboard.statistics.nbQuestionnairesInProgress' })}
            />
          </Box>
        </Stack>
      </Stack>
      <Stack width="95%" alignSelf="center">
        <Typography variant="h2" color="primary.main" align="left" marginBottom={2} marginTop={'36px'}>
          {intl.formatMessage({ id: 'dashboard.header.questionnaire' })}
        </Typography>
        <Paper sx={{ padding: '1.5rem', borderRadius: '16px' }}>
          <Grid container spacing={4}>
            <Grid item container xs={12} md={2.4} alignContent="space-between">
              <FakeLogoTenzing sujetsWithNbRespondents={sujetsWithNbRespondents} />
              <Grid item xs={12} marginY={2} textAlign="left">
                <Typography variant="body2" color="primary.main" component="span" fontWeight={700}>
                  {`${sujetsWithNbRespondents.filter((el) => el.nbRespondents > 0).length}
           ${intl.formatMessage({ id: 'dashboard.questionnaireSummary.partOne' })} ${sujetsWithNbRespondents.length} `}
                </Typography>
                <Typography variant="body2" component="span" fontWeight={500}>
                  {intl.formatMessage({ id: 'dashboard.questionnaireSummary.partTwo' })}
                </Typography>
              </Grid>
            </Grid>
            <SubjectsList sujetsWithNbRespondents={sujetsWithNbRespondents} />
          </Grid>
        </Paper>
      </Stack>
    </Layout>
  );
}
