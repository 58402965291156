import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Grid, Paper, Typography } from '@mui/material';
import { SujetsWithNbRespondents } from '@shared/src/api/reponse-partie-prenante/dto/reponse-partie-prenante.dto';
import { useLang } from '@shared/src/components/providers/LangProvider';

export type SubjectsListProps = {
  sujetsWithNbRespondents: SujetsWithNbRespondents[];
};
export function SubjectsList(props: SubjectsListProps) {
  const { sujetsWithNbRespondents } = props;
  const { lang } = useLang();
  return (
    <Grid item container xs={12} md={9.6} rowSpacing={'16px'} columnSpacing={'24px'}>
      {sujetsWithNbRespondents.map((element) => (
        <Grid item xs={12} md={4} key={element.sujet_id}>
          {element.nbRespondents === 0 && (
            <Paper
              sx={{
                alignSelf: 'center',
                padding: '0.5rem',
                backgroundColor: 'secondary.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: '4rem',
              }}
            >
              <Typography variant="body2" align="left">
                {element.sujet_nom[lang]}
              </Typography>
              <AccessTimeIcon sx={{ color: 'primary.main', alignSelf: 'right' }} />
            </Paper>
          )}
          {element.nbRespondents > 0 && (
            <Paper
              sx={{
                alignSelf: 'center',
                padding: '0.5rem',
                backgroundColor: 'primary.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: '4rem',
              }}
            >
              <Typography variant="body2" align="left" color="white">
                {element.sujet_nom[lang]}
              </Typography>
              <TaskAltIcon sx={{ color: 'white', alignSelf: 'right' }} />
            </Paper>
          )}
        </Grid>
      ))}
    </Grid>
  );
}
