import { StakeholdersPage } from '../../pages/projets/StakeholdersPage';
import { StakeholdersLabelTab } from './StakeholdersLabelTab';

export function StakeholdersLabelPage() {
  return (
    <StakeholdersPage>
      <StakeholdersLabelTab />
    </StakeholdersPage>
  );
}
