import DownloadIcon from '@mui/icons-material/Download';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  Display,
  MatrixDto,
  MatrixExport,
  MatrixFilterPropsEventHandler,
  MatrixFilterType,
  MatrixItem,
} from '@shared/src/api/matrix/dto/matrix.dto';
import { PillierDto } from '@shared/src/api/pillier/dto/pillier.dto';
import LogoutAlert from '@shared/src/components/LogoutAlert';
import { useLang } from '@shared/src/components/providers/LangProvider';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { useUtilisateur } from '@shared/src/components/providers/UtilisateurProvider';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { commentsService } from '@shared/src/services/CommentsService';
import { matrixService } from '@shared/src/services/MatrixService';
import { ServerResponse } from '@shared/src/services/ServerResponse';
import { toSvg } from 'html-to-image';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { Matrix } from '../../components/Matrix/Matrix';
import { MatrixFilter } from '../../components/Matrix/MatrixFilter';

export function MatrixPage() {
  const { lang } = useLang();
  const intl = useIntl();
  const { projetId } = useParams();
  const { utilisateur } = useUtilisateur();
  const { projet, organisation } = useProjet();
  const [items, setItems] = useState<MatrixItem[]>([]);
  const [pilliers, setPilliers] = useState<PillierDto[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const getInitialFilters = (): MatrixFilterType => {
    return {
      display:
        JSON.parse(localStorage.getItem('filters_display') ?? '[]') === Display.SOUS_SUJET
          ? Display.SOUS_SUJET
          : Display.SUJET,
      piliers: JSON.parse(localStorage.getItem('filters_piliers') ?? '[]'),
      sujets: JSON.parse(localStorage.getItem('filters_sujets') ?? '[]'),
      categoriePartiePrenante: JSON.parse(localStorage.getItem('filters_categoriePartiePrenante') ?? '[]'),
      genre: JSON.parse(localStorage.getItem('filters_genre') ?? '[]'),
      age: JSON.parse(localStorage.getItem('filters_age') ?? '[]'),
      csp: JSON.parse(localStorage.getItem('filters_csp') ?? '[]'),
      secteur: JSON.parse(localStorage.getItem('filters_secteur') ?? '[]'),
      fonction: JSON.parse(localStorage.getItem('filters_fonction') ?? '[]'),
      categorieEntreprise: JSON.parse(localStorage.getItem('filters_categorieEntreprise') ?? '[]'),
    };
  };
  const [filters, setFilters] = useState<MatrixFilterType>(getInitialFilters());

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  useEffect(
    function () {
      setLoading(true);

      if (!projetId) {
        setItems([]);
        return;
      }

      const abortController = new AbortController();
      matrixService
        .getItems(filters, {
          signal: abortController.signal,
        })
        .then(function (response: ServerResponse<MatrixDto>) {
          if ('statusCode' in response) {
            return Promise.reject(response);
          }
          setItems(
            response.data.items.map((item) => {
              return { ...item, insideOut: item.y, outsideIn: item.x };
            }),
          );
          setPilliers(response.data.pilliers);
          setLoading(false);
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          setLoading(false);
          console.error(error);
        });

      return function () {
        abortController.abort();
      };
    },
    [filters, projetId],
  );

  const onChangeFilter = useCallback<MatrixFilterPropsEventHandler>(function (event) {
    const { filters } = event;

    localStorage.setItem('filters_display', JSON.stringify(filters.display));
    localStorage.setItem('filters_piliers', JSON.stringify(filters.piliers));
    localStorage.setItem('filters_sujets', JSON.stringify(filters.sujets));
    localStorage.setItem('filters_categoriePartiePrenante', JSON.stringify(filters.categoriePartiePrenante));
    localStorage.setItem('filters_genre', JSON.stringify(filters.genre));
    localStorage.setItem('filters_age', JSON.stringify(filters.age));
    localStorage.setItem('filters_csp', JSON.stringify(filters.csp));
    localStorage.setItem('filters_secteur', JSON.stringify(filters.secteur));
    localStorage.setItem('filters_fonction', JSON.stringify(filters.fonction));
    localStorage.setItem('filters_categorieEntreprise', JSON.stringify(filters.categorieEntreprise));
    setFilters(filters);
  }, []);

  const downloadMatrixSvg = () => {
    const doc = document.getElementById('matrix-and-legend');
    if (doc) {
      toSvg(doc).then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'matrice.svg';
        link.href = dataUrl;
        link.click();
        document.getElementById('export-matrix-button')?.appendChild(link);
      });
    }
  };

  const downloadResponsesCsv = () => {
    const Papa = require('papaparse');
    commentsService
      .findAll()
      .then(function (response) {
        if ('statusCode' in response) {
          return Promise.reject(response);
        }
        const csvString =
          '\ufeff' +
          Papa.unparse(
            [
              ['Sujet', 'Réponse', 'Nom', 'Prénom', 'Email', 'Catégorie'],
              ...response.data.map((response) => {
                return [
                  response.sujet_nom.fr,
                  response.comment_comment,
                  response.user_nom,
                  response.user_prenom,
                  response.user_email,
                  response.category_nom.fr,
                ];
              }),
            ],
            {
              quotes: true,
              delimiter: ',',
              header: true,
            },
          );
        const csvData = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const csvURL = window.URL.createObjectURL(csvData);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'reponses.csv');
        tempLink.click();
      })
      .catch(function (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      });
  };

  const downloadMatrixCsv = () => {
    const abortController = new AbortController();
    matrixService
      .getExport(lang, {
        signal: abortController.signal,
      })
      .then(function (response: ServerResponse<MatrixExport>) {
        if ('statusCode' in response) {
          return Promise.reject(response);
        }
        let dataSousSujet: Record<string, Record<string, number>> = {};
        let dataSujet: Record<string, Record<string, number>> = {};
        // En sortie on aura ce format :
        // [
        //   { sousSujet1: [{ categorie1: moyenne1, categorie2: moyenne2 }] },
        //   { sousSujet2: [{ categorie1: moyenne1, categorie2: moyenne2 }] },
        // ];

        response.data.dataSousSujet.forEach(({ sousSujet, moyenne, categorie }) => {
          if (!dataSousSujet[sousSujet]) dataSousSujet[sousSujet] = {};
          dataSousSujet[sousSujet][categorie] = +(+moyenne).toFixed(2);
        });
        response.data.dataSujet.forEach(({ sujet, moyenne, categorie }) => {
          if (!dataSujet[sujet]) dataSujet[sujet] = {};
          dataSujet[sujet][categorie] = +(+moyenne).toFixed(2);
        });

        const Papa = require('papaparse');
        items.map((item) => item.label);
        const csvString =
          '\ufeff' +
          Papa.unparse(
            [
              [`${organisation?.nom} - ${projet?.nom} - ${new Date().toLocaleDateString()}`],
              [''],
              [
                intl.formatMessage({ id: 'enum.display.SOUS_SUJET' }).toUpperCase(),
                ...response.data.categories.map(({ categorie }) => categorie),
                'Moyenne',
                'Sujet',
              ],
              ...response.data.sousSujets.map(({ sousSujet, sujet, moyenne }) => {
                return [
                  sousSujet,
                  ...response.data.categories.map(({ categorie }) => dataSousSujet[sousSujet]?.[categorie]),
                  +(+moyenne).toFixed(2),
                  sujet,
                ];
              }),
              [''],
              [
                intl.formatMessage({ id: 'enum.display.SUJET' }).toUpperCase(),
                ...response.data.categories.map(({ categorie }) => categorie),
                'Moyenne',
              ],
              ...response.data.sujets.map(({ sujet, moyenne }) => {
                return [
                  sujet,
                  ...response.data.categories.map(({ categorie }) => dataSujet[sujet]?.[categorie]),
                  +(+moyenne).toFixed(2),
                ];
              }),
              ['Moyenne', ...response.data.categories.map(({ moyenne }) => +(+moyenne).toFixed(2))],
            ],
            {
              quotes: true,
              delimiter: ',',
            },
          );
        const csvData = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const csvURL = window.URL.createObjectURL(csvData);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute(
          'download',
          `matrix-${organisation?.nom}-${projet?.nom}-${new Date().toLocaleDateString()}.csv`,
        );
        tempLink.click();
      })
      .catch(function (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      });

    return function () {
      abortController.abort();
    };
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    setDrawerOpen(open);
  };

  const CustomHeader: React.FC = () => (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          mx: '2rem',
        }}
      >
        <Typography alignSelf="center" variant="h1">
          <FormattedMessage id="matrix.title" />
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignContent: 'center',
          mx: '2rem',
        }}
      >
        <Button variant="contained" startIcon={<FilterListIcon />} onClick={toggleDrawer(true)}>
          <FormattedMessage id="matrix.filter.button" />
        </Button>
      </Box>
    </>
  );

  return (
    <Layout>
      <CustomHeader />
      <LogoutAlert alertOpen={alertOpen} setAlertOpen={setAlertOpen}></LogoutAlert>
      <Stack spacing={5} direction="column" alignItems="center">
        <Stack direction="column" sx={{ flexShrink: 0, width: '100%' }}>
          <Matrix items={items} pilliers={pilliers} />
          <Stack direction={{ xs: 'column-reverse', md: 'row' }} justifyContent={'center'} spacing={4} padding={5}>
            <Button
              variant="contained"
              id="export-matrix-button"
              startIcon={<DownloadIcon />}
              onClick={() => downloadMatrixSvg()}
            >
              <FormattedMessage id="matrix.export.button" />
            </Button>
            {utilisateur?.role === UtilisateurRole.TENZING && (
              <Button
                variant="contained"
                id="export-matrix-csv-button"
                startIcon={<DownloadIcon />}
                onClick={() => downloadMatrixCsv()}
              >
                <FormattedMessage id="matrix.export-matrix.button" />
              </Button>
            )}
            <Button
              variant="contained"
              id="export-matrix-responses-button"
              startIcon={<DownloadIcon />}
              onClick={() => downloadResponsesCsv()}
            >
              <FormattedMessage id="matrix.export-responses.button" />
            </Button>
            <Button variant="contained" startIcon={<FilterListIcon />} onClick={toggleDrawer(true)}>
              <FormattedMessage id="matrix.filter.button" />
            </Button>
          </Stack>

          <MatrixFilter
            filters={filters}
            onChange={onChangeFilter}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
          />
        </Stack>
      </Stack>
    </Layout>
  );
}
