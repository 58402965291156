import { GlobalSettingsPage } from '../../pages/global-settings/GlobalSettings';
import { SubsubjectsTab } from './SubsubjectsTab';

export function SubsubjectsGlobal() {
  return (
    <GlobalSettingsPage>
      <SubsubjectsTab />
    </GlobalSettingsPage>
  );
}
