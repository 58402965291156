import { StakeholdersPage } from '../../pages/projets/StakeholdersPage';
import { MatchingTab } from './MatchingTab';

export function MatchingPage() {
  return (
    <StakeholdersPage>
      <MatchingTab />
    </StakeholdersPage>
  );
}
