import { Stack } from '@mui/material';
import { LastQuestionComponent } from '@shared/src/components/questionnaire/End/LastQuestionComponent';
import { useParams } from 'react-router-dom';

export function LastQuestionPage() {
  const { organisationId, projetId, categorieId } = useParams();

  return (
    <Stack sx={{ margin: 4 }}>
      <LastQuestionComponent
        nextStepUrl={`/${organisationId}/projets/${projetId}/questionnaire/preview/${categorieId}/end`}
        parcours={null}
      />
    </Stack>
  );
}
