import WarningIcon from '@mui/icons-material/Warning';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GridRowId } from '@mui/x-data-grid';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export type EmailAlertProps = {
  changeStatut: boolean;
  alertOpen: { open: boolean; ppToEmail: { id: GridRowId | null; nom: string; prenom: string }[] };
  setAlertOpen: React.Dispatch<
    React.SetStateAction<{ open: boolean; ppToEmail: { id: GridRowId | null; nom: string; prenom: string }[] }>
  >;
  remindPartiePrenantes: () => void;
};

export default function EmailAlert(props: EmailAlertProps) {
  const { changeStatut, alertOpen, setAlertOpen, remindPartiePrenantes } = props;

  const handleClose = (validation: boolean) => {
    setAlertOpen({ open: false, ppToEmail: [] });
    if (validation && alertOpen.ppToEmail.length > 0) {
      remindPartiePrenantes();
    }
  };

  return (
    <Dialog open={alertOpen.open} onClose={() => handleClose(false)}>
      <DialogTitle textAlign="center">
        <FormattedMessage id="ppList.email.alert.title" />
      </DialogTitle>
      <DialogContent>
        {changeStatut && (
          <DialogContentText sx={{ color: 'primary.main', fontWeight: 600, mb: '2rem' }}>
            <Grid container>
              <Grid item xs={2} sm={1} sx={{ display: 'flex', alignItems: 'center' }}>
                <WarningIcon />
              </Grid>
              <Grid item xs={10} sm={11}>
                <FormattedMessage id="ppList.email.alert.content.statut" />
              </Grid>
            </Grid>
          </DialogContentText>
        )}
        <DialogContentText>
          <FormattedMessage id="ppList.email.alert.content.partOne" />
        </DialogContentText>
        <DialogContentText>
          <ul>
            {alertOpen.ppToEmail.map((pp) => (
              <li>{`${pp.nom} ${pp.prenom}`}</li>
            ))}
          </ul>
        </DialogContentText>
        <DialogContentText>
          <FormattedMessage id="ppList.email.alert.content.partTwo" />
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button style={{ minWidth: 'auto' }} onClick={() => handleClose(false)} id="ppList-alert-button-no" autoFocus>
          <FormattedMessage id="all.no" />
        </Button>
        <Button style={{ minWidth: 'auto' }} onClick={() => handleClose(true)} id="ppList-alert-button-yes">
          <FormattedMessage id="all.yes" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
