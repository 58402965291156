import Typography from '@mui/material/Typography';
import Layout from '../../components/Layout/Layout';

export function NotFoundPage() {
  return (
    <Layout>
      <Typography variant="h1">404</Typography>
      <Typography variant="h2">Page not found</Typography>
    </Layout>
  );
}
