import { Box, Button, Stack } from '@mui/material';
import { LegalInformation } from '@shared/src/components/legal/LegalInformation';
import { FormattedMessage } from 'react-intl';
import Layout from '../../components/Layout/Layout';

export function LegalInformationPage() {
  return (
    <Layout connected={false}>
      <Stack mx="2rem">
        <LegalInformation />
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Button href="/auth/login" variant="contained" sx={{ width: '15rem' }}>
            <FormattedMessage id="header.goToHome-button" />
          </Button>
        </Box>
      </Stack>
    </Layout>
  );
}
