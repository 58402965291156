import { GlobalSettingsPage } from '../../pages/global-settings/GlobalSettings';
import { SubjectsTab } from './SubjectsTab';

export function SubjectsGlobal() {
  return (
    <GlobalSettingsPage>
      <SubjectsTab />
    </GlobalSettingsPage>
  );
}
