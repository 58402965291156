import { QuestionnairePage } from '../../pages/projets/QuestionnairePage';
import { ExplanationTab } from './ExplanationTab';

export function ExplanationPage() {
  return (
    <QuestionnairePage>
      <ExplanationTab />
    </QuestionnairePage>
  );
}
