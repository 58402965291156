import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { QuestionDto } from '@shared/src/api/question/dto/question.dto';
import { SujetDto } from '@shared/src/api/sujet/dto/sujet.dto';
import { useLang } from '@shared/src/components/providers/LangProvider';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { useUtilisateur } from '@shared/src/components/providers/UtilisateurProvider';
import { StatutProjet } from '@shared/src/enum/projet.enum';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { questionsService } from '@shared/src/services/QuestionsService';
import { sujetsService } from '@shared/src/services/SujetsService';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

export function QuestionsTab() {
  const { projetId } = useParams();
  const intl = useIntl();
  const { lang } = useLang();
  const { projet } = useProjet();
  const { utilisateur } = useUtilisateur();
  const urlParams = new URLSearchParams(window.location.search);
  const [questions, setQuestions] = useState<QuestionDto[]>([]);
  const [sujets, setSujets] = useState<SujetDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [sujetId, setSujetId] = useState<string>(urlParams.get('sujet') ?? '');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  useEffect(() => {
    sujetsService
      .findAll()
      .then(function (response) {
        if ('statusCode' in response) {
          return Promise.reject(response);
        }
        if (!sujetId) setSujetId(response.data[0].id);
        setSujets(response.data);
      })
      .catch(function (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      });
  }, [projetId, sujetId]);

  useEffect(() => {
    if (sujetId) {
      setLoading(true);
      questionsService
        .findAll(sujetId)
        .then(function (response) {
          if ('statusCode' in response) {
            return Promise.reject(response);
          }
          setQuestions(response.data);
          setLoading(false);
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          console.error(error);
        });
    }
  }, [sujetId]);

  const updateQuestions = (e: React.FormEvent) => {
    e.preventDefault();
    if (sujetId) {
      questions.forEach((question) => {
        questionsService.update(sujetId, question.id, { nom: question.nom });
      });
      setSnackbarMessage(intl.formatMessage({ id: 'questionnaire.questions.update' }));
      setOpenSnackbar(true);
    }
  };

  if (utilisateur?.role === UtilisateurRole.TENZING && projet?.statut === StatutProjet.CREE) {
    return (
      <Stack component="form" onSubmit={updateQuestions}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            setOpenSnackbar(false);
            setSnackbarMessage('');
          }}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Paper sx={{ mb: '2rem', p: '2rem' }}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="subject-choice-label">
              {intl.formatMessage({ id: 'questionnaire.subjects.title' })}
            </InputLabel>
            <Select
              labelId="subject-choice-label"
              id="subject-choice"
              value={sujetId}
              label={intl.formatMessage({ id: 'questionnaire.subjects.title' })}
              onChange={(e) => {
                setSujetId(e.target.value);
              }}
              sx={{ width: '100%' }}
            >
              {sujets.map((sujet) => (
                <MenuItem key={sujet.nom[lang]} value={sujet.id}>
                  {sujet.nom[lang]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!loading &&
            questions?.map((question) => (
              <FormControl sx={{ width: '100%' }}>
                <Grid container spacing={2} key={question.id} sx={{ pt: '2rem' }}>
                  <Grid item display="flex" xs={12}>
                    <Typography variant="h3">{question.type}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id={`${question.id}-name-fr`}
                      label={intl.formatMessage({ id: 'questionnaire.name.fr' })}
                      defaultValue={question.nom.fr ?? ''}
                      autoComplete="no"
                      multiline
                      rows={4}
                      onChange={(e) => {
                        question.nom.fr = e.target.value;
                      }}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id={`${question.id}-name-en`}
                      label={intl.formatMessage({ id: 'questionnaire.name.en' })}
                      defaultValue={question.nom.en ?? ''}
                      autoComplete="no"
                      multiline
                      rows={4}
                      onChange={(e) => {
                        question.nom.en = e.target.value;
                      }}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            ))}
        </Paper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button type="submit" variant="contained" sx={{ width: '15rem' }}>
            {intl.formatMessage({ id: 'questionnaire.save' })}
          </Button>
        </Box>
      </Stack>
    );
  } else {
    return (
      <TableContainer component={Paper} sx={{ borderRadius: '1rem', padding: '1rem' }}>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="subject-choice-label">
            {intl.formatMessage({ id: 'questionnaire.subjects.title' })}
          </InputLabel>
          <Select
            labelId="subject-choice-label"
            id="subject-choice"
            value={sujetId}
            label={intl.formatMessage({ id: 'questionnaire.subjects.title' })}
            onChange={(e) => {
              setSujetId(e.target.value);
            }}
          >
            {sujets.map((sujet) => (
              <MenuItem key={sujet.nom[lang]} value={sujet.id}>
                {sujet.nom[lang]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Table stickyHeader>
          <colgroup>
            <col style={{ width: '10%' }} />
            <col style={{ width: '45%' }} />
            <col style={{ width: '45%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'questionnaire.type' })}
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'questionnaire.name.fr' })}
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'questionnaire.name.en' })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map((question) => (
              <TableRow key={question.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" align="left">
                  {question.type}
                </TableCell>
                <TableCell component="th" align="left">
                  {question.nom.fr || '/'}
                </TableCell>
                <TableCell component="th" align="left">
                  {question.nom.en || '/'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
