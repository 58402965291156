import { CategoriePartiePrenanteDto } from '@shared/src/api/categorie-partie-prenante/dto/categorie-partie-prenante.dto';
import { ExplanationComponent } from '@shared/src/components/questionnaire/Explanation/ExplanationComponent';
import { useSujetsByCategory } from '@shared/src/hooks/useSujetsByCategory';
import { categoriePartiePrenantesService } from '@shared/src/services/CategoriePartiePrenantesService';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export function ExplanationPage() {
  const { organisationId, projetId, categorieId } = useParams();
  const { sujets } = useSujetsByCategory(projetId, categorieId);
  const [categorie, setCategorie] = useState<CategoriePartiePrenanteDto>();

  useEffect(() => {
    categoriePartiePrenantesService
      .findOne(categorieId ?? '')
      .then(function (response) {
        if ('statusCode' in response) {
          return Promise.reject(response);
        }
        setCategorie(response.data ?? undefined);
      })
      .catch(function (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      });
  }, [projetId, categorieId]);

  if (!categorie || !sujets) {
    return null;
  }

  return (
    <ExplanationComponent
      categorie={categorie}
      updateParcours={false}
      parcours={null}
      sujets={sujets}
      nextStepUrl={`/${organisationId}/projets/${projetId}/questionnaire/preview/${categorieId}/sujet/1`}
    />
  );
}
