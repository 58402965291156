import { Paper, Skeleton, Typography } from '@mui/material';

export type StatisticBlockProps = {
  keyValue: string;
  legend: string;
  isLoading?: boolean;
};

export function StatisticBlock(props: StatisticBlockProps) {
  const { keyValue, legend, isLoading } = props;
  return (
    <Paper
      sx={{
        height: '198px',
        width: '198px',
        borderRadius: '14.08px',
        paddingTop: '12px',
      }}
    >
      <Typography alignSelf="center" color="primary.main" variant="subtitle2">
        {isLoading ? <Skeleton width="148px" sx={{ marginX: '25px' }} /> : keyValue}
      </Typography>
      <Typography alignSelf="center" variant="body2" marginLeft="30px" marginRight="30px">
        {legend}
      </Typography>
    </Paper>
  );
}
