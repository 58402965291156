import { GlobalSettingsPage } from '../../pages/global-settings/GlobalSettings';
import { StakeholdersLabelTab } from './StakeholdersLabelTab';

export function StakeholdersLabelGlobal() {
  return (
    <GlobalSettingsPage>
      <StakeholdersLabelTab />
    </GlobalSettingsPage>
  );
}
