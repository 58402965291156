import { Box, Card, Container, Typography } from '@mui/material';
import { ResetPasswordChangeForm } from '@shared/src/components/login/ResetPasswordChangeForm';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';

export function ResetPasswordChangePage() {
  const { token } = useParams<{ token: string }>();
  return (
    <Layout maxWidth="md" connected={false}>
      <Container maxWidth="sm">
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <Card sx={{ p: 4, width: '100%' }}>
            <Typography variant="h4" gutterBottom align="center">
              <FormattedMessage id="reset-password.change-form.title" />
            </Typography>
            <ResetPasswordChangeForm resetToken={token || ''} />
          </Card>
        </Box>
      </Container>
    </Layout>
  );
}
