import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Stack, Typography } from '@mui/material';
import { ProtectedRoute } from '@shared/src/components/ProtectedRoute';
import { useLang } from '@shared/src/components/providers/LangProvider';
import { Provider } from '@shared/src/components/providers/ProjetProvider';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { useCategoriesPartiesPrenantes } from '@shared/src/hooks/useCategoriesPartiesPrenantes';
import { FormattedMessage } from 'react-intl';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { EndPage } from './EndPage';
import { ExplanationPage } from './ExplanationPage';
import { LastQuestionPage } from './LastQuestionPage';
import { ProfilingPage } from './ProfilingPage';
import { SujetPage } from './SujetPage';

export function QuestionnairePreviewRouter() {
  const { lang } = useLang();
  const { organisationId, projetId, categorieId } = useParams();
  const { categoriesPartiesPrenantes } = useCategoriesPartiesPrenantes();
  const navigation = useNavigate();

  return (
    <Provider projetId={projetId} organisationId={organisationId}>
      <Stack
        sx={{
          width: '100%',
          backgroundColor: 'secondary.dark',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0.5rem',
          borderRadius: '0.5rem',
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" marginRight="0.2rem">
            <FormattedMessage id="questionnaire.preview.banner.message" />
          </Typography>
          {categoriesPartiesPrenantes && (
            <Typography variant="body2" fontWeight={800}>
              {categoriesPartiesPrenantes.find((el) => el.id === categorieId)?.nom[lang]}
            </Typography>
          )}
        </Stack>
        <Button
          variant="text"
          endIcon={<ArrowForwardIcon />}
          sx={{ color: 'text.primary' }}
          onClick={() => navigation(`/${organisationId}/projets/${projetId}/questionnaire/preview`)}
        >
          <FormattedMessage id="questionnaire.preview.banner.goBackButton" />
        </Button>
      </Stack>
      <Routes>
        <Route element={<ProtectedRoute role={[UtilisateurRole.TENZING, UtilisateurRole.CLIENT]} />}>
          <Route path="/start" Component={ExplanationPage} />
          <Route path="/sujet/:position" Component={SujetPage} />
          <Route path="/profiling" Component={ProfilingPage} />
          <Route path="/last-question" Component={LastQuestionPage} />
          <Route path="/end" Component={EndPage} />
        </Route>
      </Routes>
    </Provider>
  );
}
