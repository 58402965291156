import { ProtectedRoute } from '@shared/src/components/ProtectedRoute';
import { Provider } from '@shared/src/components/providers/ProjetProvider';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { BeforeFetchEvent, addEventListener, removeEventListener } from '@shared/src/services/HttpService';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { ExplanationPage } from '../../components/Questionnaire/ExplanationPage';
import { PreviewTab } from '../../components/Questionnaire/PreviewTab';
import { QuestionsPage } from '../../components/Questionnaire/QuestionsPage';
import { SubjectsPage } from '../../components/Questionnaire/SubjectsPage';
import { SubsubjectsPage } from '../../components/Questionnaire/SubsubjectsPage';
import { MatchingPage } from '../../components/Stakeholders/MatchingPage';
import { StakeholdersLabelPage } from '../../components/Stakeholders/StakeholdersLabelPage';
import { StakeholdersListingPage } from '../../components/Stakeholders/StakeholdersListingPage';
import { DashboardPage } from './DashboardPage';
import { MatrixPage } from './MatrixPage';
import { SettingsPage } from './SettingsPage';

export function ProjetsRouter() {
  const { organisationId, projetId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const setupProjetId = useCallback(
    function onBeforeFetch(event: BeforeFetchEvent) {
      if (!projetId) return;
      const url = new URL(event.url);
      url.searchParams.set('projetId', projetId);
      event.url = url.toString();
      setIsLoading(false);
    },
    [projetId],
  );

  useEffect(() => {
    if (!projetId) return;
    addEventListener('before:fetch', setupProjetId);
    return function () {
      removeEventListener('before:fetch', setupProjetId);
    };
  }, [projetId, setupProjetId]);

  if (isLoading) {
    return null;
  }
  return (
    <Provider organisationId={organisationId} projetId={projetId}>
      <Routes>
        <Route element={<ProtectedRoute role={[UtilisateurRole.TENZING, UtilisateurRole.CLIENT]} />}>
          <Route path="/" element={<Navigate to="dashboard" />} />
          <Route path="/dashboard/*" Component={DashboardPage} />
          <Route path="/matrix/*" Component={MatrixPage} />
          <Route path="/stakeholders" element={<Navigate to="listing" />} />
          <Route path="/stakeholders/label/*" Component={StakeholdersLabelPage} />
          <Route path="/stakeholders/matching/*" Component={MatchingPage} />
          <Route path="/stakeholders/listing/*" Component={StakeholdersListingPage} />
          <Route path="/questionnaire/explication/*" Component={ExplanationPage} />
          <Route path="/questionnaire/preview/*" Component={PreviewTab} />
          <Route path="/questionnaire" element={<Navigate to="preview" />} />
        </Route>
        <Route path="/questionnaire/sujet/*" element={<ProtectedRoute role={[UtilisateurRole.TENZING]} />}>
          <Route path="/questionnaire/sujet/*" Component={SubjectsPage} />
        </Route>
        <Route path="/questionnaire/question/*" element={<ProtectedRoute role={[UtilisateurRole.TENZING]} />}>
          <Route path="/questionnaire/question/*" Component={QuestionsPage} />
        </Route>
        <Route path="/questionnaire/sous-sujet/*" element={<ProtectedRoute role={[UtilisateurRole.TENZING]} />}>
          <Route path="/questionnaire/sous-sujet/*" Component={SubsubjectsPage} />
        </Route>
        <Route path="/settings/*" element={<ProtectedRoute role={[UtilisateurRole.TENZING]} />}>
          <Route path="/settings/*" Component={SettingsPage} />
        </Route>
      </Routes>
    </Provider>
  );
}
