import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Box, Button, Container, FormControlLabel, Paper, Stack, Switch, TextField, Typography } from '@mui/material';
import { ProjetDto } from '@shared/src/api/projet/dto/projet.dto';
import { StatutProjet } from '@shared/src/enum/projet.enum';
import { projetsService } from '@shared/src/services/ProjetsService';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import CloseAlert from '../../components/Settings/CloseAlert';
import { TableAdmin } from '../../components/Settings/TableAdmin';

export const SettingsPage: React.FC<PropsWithChildren<{}>> = () => {
  const { organisationId, projetId } = useParams();
  const intl = useIntl();

  const [projet, setProjet] = useState<ProjetDto>({
    id: '',
    nom: '',
    organisationId: '',
    statut: StatutProjet.CREE,
    settings: {},
  });
  const [closeAlertOpen, setCloseAlertOpen] = useState<boolean>(false);

  useEffect(() => {
    if (organisationId && projetId)
      projetsService
        .findOne(projetId)
        .then(function (response) {
          if ('statusCode' in response || !response.data) {
            return Promise.reject(response);
          }
          if (response.data) setProjet(response.data);
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          console.error(error);
        });
    if (organisationId && !projetId) {
      setProjet({ id: '', nom: '', organisationId, statut: StatutProjet.CREE, settings: {} });
    }
  }, [organisationId, projetId]);

  const saveProjet = () => {
    if (organisationId) {
      if (projetId) {
        projetsService.update(projetId, projet).then(function (response) {
          if ('statusCode' in response || !response.data) {
            return Promise.reject(response);
          }
          if (response.data) {
            window.location.href = `/${organisationId}/projets/${projetId}`;
          }
        });
      } else
        projetsService
          .create({
            nom: projet.nom,
            statut: StatutProjet.CREE,
            demarreLe: new Date(),
            organisationId,
            lastUpdateStatut: new Date(),
            settings: projet.settings,
          })
          .then(function (response) {
            if ('statusCode' in response || !response.data) {
              return Promise.reject(response);
            }
            if (response.data) {
              window.location.href = `/${organisationId}/projets/${projetId ?? response.data.id}`;
            }
          });
    }
  };

  const closeProjet = () => {
    if (organisationId) {
      if (projetId) {
        projetsService.updateStatut(projetId, StatutProjet.CLOS).then(function (response) {
          if ('statusCode' in response || !response.data) {
            return Promise.reject(response);
          }
          if (response.data) {
            window.location.href = `/${organisationId}/projets/${projetId}`;
          }
        });
      }
    }
  };

  return (
    <Layout>
      <CloseAlert
        alertOpen={closeAlertOpen}
        setAlertOpen={setCloseAlertOpen}
        title={intl.formatMessage({ id: 'projet.closeProjet.alert.title' })}
        content={intl.formatMessage({ id: 'projet.closeProjet.alert.statut' })}
        closeProjet={closeProjet}
      />
      <Box
        sx={{
          display: 'flex',
          mx: '2rem',
        }}
      >
        <Typography alignSelf="center" variant="h1">
          {projetId ? <FormattedMessage id="projet.edit.title" /> : <FormattedMessage id="projet.create.title" />}
        </Typography>
      </Box>
      <Container sx={{ mt: '2rem' }}>
        <Paper sx={{ p: '2rem' }}>
          <Stack>
            <TextField
              id={`projet-name`}
              label={intl.formatMessage({ id: 'projet.edit.name' })}
              value={projet.nom}
              onChange={(e) => {
                setProjet({ ...projet, nom: e.target.value });
              }}
              sx={{ width: { xs: '100%', md: '50%' } }}
            />
            <Typography align="left" variant="h3" component="h2" m="1rem">
              <FormattedMessage id="projet.edit.options" />
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  sx={{
                    '& .MuiSwitch-thumb': {
                      boxShadow: '0px 1px 1px grey',
                    },
                  }}
                  checked={projet.settings?.noGender}
                  onChange={(e) =>
                    setProjet({
                      ...projet,
                      settings: { ...projet.settings, noGender: e.target.checked },
                    })
                  }
                />
              }
              label={intl.formatMessage({ id: 'projet.edit.noGender' })}
            />
            <Box display="flex" justifyContent="space-between">
              <Button
                onClick={saveProjet}
                variant="contained"
                sx={{ mt: '2rem', width: '15rem' }}
                disabled={projet.nom === ''}
              >
                {intl.formatMessage({ id: 'questionnaire.save' })}
              </Button>
              {projetId && (
                <Button
                  onClick={() => setCloseAlertOpen(true)}
                  variant="outlined"
                  startIcon={<CancelOutlinedIcon />}
                  sx={{ mt: '2rem', width: '15rem' }}
                  disabled={projet.statut === StatutProjet.CLOS}
                >
                  {intl.formatMessage({ id: 'projet.closeProjet.button' })}
                </Button>
              )}
            </Box>
          </Stack>
        </Paper>
        {projetId && <TableAdmin />}
      </Container>
    </Layout>
  );
};
