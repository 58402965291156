import { QuestionnairePage } from '../../pages/projets/QuestionnairePage';
import { QuestionsTab } from './QuestionsTab';

export function QuestionsPage() {
  return (
    <QuestionnairePage>
      <QuestionsTab />
    </QuestionnairePage>
  );
}
