import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { OrganisationDto } from '@shared/src/api/organisation/dto/organisation.dto';
import { NoLogo } from '@shared/src/components/NoLogo';
import { useUtilisateur } from '@shared/src/components/providers/UtilisateurProvider';
import { StatutOrganisation } from '@shared/src/enum/organisation.enum';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { organisationsService } from '@shared/src/services/OrganisationsService';
import { PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';

type BlockProps = {
  organisation: OrganisationDto;
  isAdminTenzing: boolean;
};

const OrganisationBlock: React.FC<PropsWithChildren<BlockProps>> = ({ organisation, isAdminTenzing }) => {
  const intl = useIntl();
  const firstProjet = organisation.projets?.[0]?.id ? `projets/${organisation.projets[0].id}` : 'projet/create';

  return (
    <Box sx={{ height: '5rem' }}>
      <Button
        key={organisation.id}
        href={`${organisation.id}/${firstProjet}`}
        variant="contained"
        endIcon={<ArrowForwardIcon />}
        sx={{
          backgroundColor: 'primary.light',
          color: 'text.primary',
          ':hover': { backgroundColor: 'secondary.dark' },
          height: '100%',
          zIndex: 2,
          justifyContent: 'space-between',
          pr: '1rem',
          width: 'calc(100% - 5rem)',
        }}
      >
        <Box
          sx={{
            minWidth: 60,
            maxWidth: 60,
          }}
        >
          {organisation.logo && organisation.logo.length > 0 && (
            <img src={`${organisation.logo}`} alt="logo" width={60} />
          )}
          {(!organisation.logo || organisation.logo.length === 0) && <NoLogo size="medium" />}
        </Box>
        {organisation.statut === StatutOrganisation.CLOS && intl.formatMessage({ id: 'projet.closeProjet.tag' })}
        {organisation.nom}
      </Button>
      {isAdminTenzing && (
        <Button
          variant="contained"
          href={`${organisation.id}/edit`}
          endIcon={<ModeEditOutlinedIcon />}
          sx={{ backgroundColor: 'primary.main', height: '100%', minWidth: '5rem', left: '-1rem' }}
        />
      )}
    </Box>
  );
};

export function HomePage() {
  const [organisations, setOrganisations] = useState<OrganisationDto[]>([]);
  const [organisation, setOrganisation] = useState<OrganisationDto | null>(null);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const { utilisateur } = useUtilisateur();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  localStorage.clear();
  useEffect(
    function () {
      const abortController = new AbortController();
      if (utilisateur?.role === UtilisateurRole.TENZING) {
        setAuthorized(true);
        organisationsService
          .findAll()
          .then(function (response) {
            if ('statusCode' in response) {
              return Promise.reject(response);
            }
            setOrganisations(response.data);
            setIsLoading(false);
          })
          .catch(function (error) {
            if (error.statusCode === 403) {
              setIsLoading(false);
              setAuthorized(false);
            }
            if (error.name === 'AbortError') return;
            console.error(error);
          });
      }
      if (utilisateur?.role === UtilisateurRole.CLIENT) {
        organisationsService
          .findOneByUtilisateurId(utilisateur.id)
          .then(function (response) {
            if ('statusCode' in response) {
              return Promise.reject(response);
            }
            if (response.data === null) {
              setAuthorized(true);
              setIsLoading(false);
            }
            if (response.data) {
              setAuthorized(true);
              setOrganisation(response.data);
              setIsLoading(false);
            }
          })
          .catch(function (error) {
            if (error.statusCode === 403) {
              setAuthorized(false);
            }
            if (error.name === 'AbortError') return;
            console.error(error);
          });
      }
      if (utilisateur?.role === UtilisateurRole.PARTIE_PRENANTE) {
        setAuthorized(false);
        setIsLoading(false);
      }

      return function () {
        abortController.abort();
      };
    },
    [utilisateur],
  );

  if (isLoading) return null;

  // Un admin Tenzing
  if (authorized && utilisateur && utilisateur.role === UtilisateurRole.TENZING) {
    return (
      <Layout>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            mx: '2rem',
            mb: '2rem',
          }}
        >
          <Typography alignSelf="center" variant="h1">
            <FormattedMessage id="organisation.title" />
          </Typography>
          <Button href="/organisation/create" variant="contained">
            <FormattedMessage id="organisation.create" />
          </Button>
        </Box>
        <Box>
          {organisations.length > 0 ? (
            <Grid container px={'2rem'} spacing={3}>
              {organisations.map((organisation) => (
                <Grid item xs={12} md={6} lg={4} key={organisation.id}>
                  <OrganisationBlock organisation={organisation} isAdminTenzing={true} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body1">
              <FormattedMessage id="organisation.noOrganisation" />
            </Typography>
          )}
        </Box>
      </Layout>
    );
  }

  // Un admin client qui a au moins un projet créé / démarré
  if (authorized && utilisateur && utilisateur.role === UtilisateurRole.CLIENT && organisation) {
    return (
      <Layout>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mx: '2rem',
            mb: '2rem',
          }}
        >
          <Typography alignSelf="center" variant="h1">
            <FormattedMessage id="organisation.title" />
          </Typography>
        </Box>
        <Box>
          <Grid container px={'2rem'} spacing={3}>
            <Grid item xs={12} md={6} lg={4} key={organisation.id}>
              <OrganisationBlock organisation={organisation} isAdminTenzing={false} />
            </Grid>
          </Grid>
        </Box>
      </Layout>
    );
  }

  // Un admin client qui n'a plus de projets créé / démarré
  if (authorized && utilisateur && utilisateur.role === UtilisateurRole.CLIENT) {
    return (
      <Layout>
        <Container sx={{ textAlign: 'left', mt: '3rem' }}>
          <FormattedMessage id="projet.closeProjet.content" />
        </Container>
      </Layout>
    );
  }

  return <Navigate to="/auth/login" />;
}
