import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { CategoriePartiePrenanteDto } from '@shared/src/api/categorie-partie-prenante/dto/categorie-partie-prenante.dto';
import { useLang } from '@shared/src/components/providers/LangProvider';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { StatutProjet } from '@shared/src/enum/projet.enum';
import { categoriePartiePrenantesService } from '@shared/src/services/CategoriePartiePrenantesService';
import { explicationService } from '@shared/src/services/ExplicationService';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

export function ExplanationTab() {
  const { projetId } = useParams();
  const intl = useIntl();
  const { lang } = useLang();
  const { projet } = useProjet();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [categories, setCategories] = useState<CategoriePartiePrenanteDto[]>([]);
  const [categoryId, setCategoryId] = useState<string>('');
  const [category, setCategory] = useState<CategoriePartiePrenanteDto>();

  useEffect(() => {
    categoriePartiePrenantesService
      .findAll()
      .then(function (response) {
        if ('statusCode' in response) {
          return Promise.reject(response);
        }
        if (!categoryId) setCategoryId(response.data[0].id);
        setCategories(response.data);
      })
      .catch(function (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      });
  }, [projetId, categoryId]);

  useEffect(() => {
    if (categoryId)
      categoriePartiePrenantesService
        .findOne(categoryId)
        .then(function (response) {
          if ('statusCode' in response) {
            return Promise.reject(response);
          }
          setCategory(response.data ?? undefined);
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          console.error(error);
        });
  }, [categoryId]);

  const updateSubjects = (e: React.FormEvent) => {
    e.preventDefault();
    if (category) {
      if (category?.explication?.id)
        explicationService.update(category.explication.id, {
          texte: category.explication.texte ?? undefined,
          labelLien: category.explication.labelLien ?? undefined,
          lien: category.explication.lien ?? undefined,
        });
      else {
        explicationService
          .create({
            texte: category.explication?.texte ?? undefined,
            labelLien: category.explication?.labelLien ?? undefined,
            lien: category.explication?.lien ?? undefined,
          })
          .then(function (response) {
            if ('statusCode' in response) {
              return Promise.reject(response);
            }
            if (response.data) {
              categoriePartiePrenantesService.update(category.id, { explicationId: response.data.id });
            }
          })
          .catch(function (error) {
            if (error.name === 'AbortError') return;
            console.error(error);
          });
      }
    }
    setSnackbarMessage(intl.formatMessage({ id: 'questionnaire.explication.update' }));
    setOpenSnackbar(true);
  };

  if (projet?.statut === StatutProjet.CREE) {
    return (
      <Stack component="form" onSubmit={updateSubjects}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            setOpenSnackbar(false);
            setSnackbarMessage('');
          }}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Paper sx={{ mb: '2rem', p: '2rem' }}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="category-choice-label">
              {intl.formatMessage({ id: 'questionnaire.categorie.choice' })}
            </InputLabel>
            <Select
              labelId="category-choice-label"
              id="category-choice"
              value={categoryId}
              label={intl.formatMessage({ id: 'questionnaire.categorie.choice' })}
              onChange={(e) => {
                setCategoryId(e.target.value);
              }}
            >
              {categories.map((categorie) => (
                <MenuItem key={categorie.nom[lang]} value={categorie.id}>
                  {categorie.nom[lang]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            {category && (
              <Grid container spacing={2} sx={{ pt: '2rem' }}>
                <Grid item xs={12}>
                  <TextField
                    id={`${category.id}-texte-fr`}
                    label={intl.formatMessage({ id: 'questionnaire.text.fr' })}
                    value={category.explication?.texte?.fr ?? ''}
                    multiline
                    minRows={5}
                    maxRows={15}
                    onChange={(e) => {
                      setCategory({
                        ...category,
                        explication: {
                          ...category.explication,
                          texte: { ...category.explication?.texte, fr: e.target.value },
                        },
                      });
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id={`${category.id}-label-link-fr`}
                    label={intl.formatMessage({ id: 'questionnaire.label-link.fr' })}
                    value={category.explication?.labelLien?.fr ?? ''}
                    required={!!category.explication?.lien?.fr}
                    onChange={(e) => {
                      setCategory({
                        ...category,
                        explication: {
                          ...category.explication,
                          labelLien: { ...category.explication?.labelLien, fr: e.target.value },
                        },
                      });
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id={`${category.id}-link-fr`}
                    label={intl.formatMessage({ id: 'questionnaire.link.fr' })}
                    value={category.explication?.lien?.fr ?? ''}
                    onChange={(e) => {
                      setCategory({
                        ...category,
                        explication: {
                          ...category.explication,
                          lien: { ...category.explication?.lien, fr: e.target.value },
                        },
                      });
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} mt={3}>
                  <TextField
                    id={`${category.id}-texte-en`}
                    label={intl.formatMessage({ id: 'questionnaire.text.en' })}
                    value={category.explication?.texte?.en ?? ''}
                    multiline
                    minRows={5}
                    maxRows={15}
                    onChange={(e) => {
                      setCategory({
                        ...category,
                        explication: {
                          ...category.explication,
                          texte: { ...category.explication?.texte, en: e.target.value },
                        },
                      });
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id={`${category.id}-label-link-en`}
                    label={intl.formatMessage({ id: 'questionnaire.label-link.en' })}
                    value={category.explication?.labelLien?.en ?? ''}
                    required={!!category.explication?.lien?.en}
                    onChange={(e) => {
                      setCategory({
                        ...category,
                        explication: {
                          ...category.explication,
                          labelLien: { ...category.explication?.labelLien, en: e.target.value },
                        },
                      });
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id={`${category.id}-link-en`}
                    label={intl.formatMessage({ id: 'questionnaire.link.en' })}
                    value={category.explication?.lien?.en ?? ''}
                    onChange={(e) => {
                      setCategory({
                        ...category,
                        explication: {
                          ...category.explication,
                          lien: { ...category.explication?.lien, en: e.target.value },
                        },
                      });
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
            )}
          </FormControl>
        </Paper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button type="submit" variant="contained" sx={{ width: '15rem' }}>
            {intl.formatMessage({ id: 'questionnaire.save' })}
          </Button>
        </Box>
      </Stack>
    );
  } else {
    return (
      <TableContainer component={Paper} sx={{ borderRadius: '1rem', padding: '1rem' }}>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="category-choice-label">
            {intl.formatMessage({ id: 'questionnaire.categorie.choice' })}
          </InputLabel>
          <Select
            labelId="category-choice-label"
            id="category-choice"
            value={categoryId}
            label={intl.formatMessage({ id: 'questionnaire.categorie.choice' })}
            onChange={(e) => {
              setCategoryId(e.target.value);
            }}
          >
            {categories.map((categorie) => (
              <MenuItem key={categorie.nom[lang]} value={categorie.id}>
                {categorie.nom[lang]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Table stickyHeader>
          <colgroup>
            <col style={{ width: '50%' }} />
            <col style={{ width: '50%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'questionnaire.text.fr' })}
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'questionnaire.text.en' })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {category && (
              <TableRow key={category.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" width="50%">
                  <Typography sx={{ whiteSpace: 'pre-wrap' }}>{category.explication?.texte?.fr || '/'}</Typography>
                  {category.explication?.lien?.fr && (
                    <Link href={category.explication?.lien?.fr} target="_blank">
                      {category.explication?.labelLien?.fr ?? category.explication?.lien?.fr}
                    </Link>
                  )}
                </TableCell>
                <TableCell component="th" align="left">
                  <Typography sx={{ whiteSpace: 'pre-wrap' }}>{category.explication?.texte?.en || '/'}</Typography>
                  {category.explication?.lien?.en && (
                    <Link href={category.explication?.lien?.en} target="_blank">
                      {category.explication?.labelLien?.en ?? category.explication?.lien?.en}
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
