import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Box, Button, Container, Paper, Stack, TextField, Typography } from '@mui/material';
import { OrganisationDto } from '@shared/src/api/organisation/dto/organisation.dto';
import { NoLogo } from '@shared/src/components/NoLogo';
import { StatutOrganisation } from '@shared/src/enum/organisation.enum';
import { organisationsService } from '@shared/src/services/OrganisationsService';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import CloseAlert from '../../components/Settings/CloseAlert';

export const OrganisationPage: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { organisationId } = useParams();
  const intl = useIntl();

  const [organisation, setOrganisation] = useState<OrganisationDto>({ id: 'temporary', nom: '', logo: '' });
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [closeAlertOpen, setCloseAlertOpen] = useState<boolean>(false);

  useEffect(() => {
    if (organisationId)
      organisationsService
        .findOne(organisationId)
        .then(function (response) {
          if ('statusCode' in response || !response.data) {
            return Promise.reject(response);
          }
          if (response.data) {
            setOrganisation(response.data);
          }
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          console.error(error);
        });
  }, [organisationId]);

  const saveOrganisation = () => {
    if (organisationId)
      organisationsService.update(organisationId, organisation).then(function (response) {
        if ('statusCode' in response || !response.data) {
          return Promise.reject(response);
        }
        if (response.data) {
          window.location.href = '/';
        }
      });
    else
      organisationsService.create(organisation).then(function (response) {
        if ('statusCode' in response || !response.data) {
          return Promise.reject(response);
        }
        if (response.data) {
          window.location.href = '/';
        }
      });
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  };

  function onImageChange(e: any) {
    const file = e.target.files[0];

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target?.result);
        const base64: string = event.target?.result?.toString() ?? '';

        setOrganisation({ ...organisation, logo: base64 });
        setEnableSave(true);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsDataURL(file);
    });
  }

  const closeOrganisation = () => {
    if (organisationId) {
      organisationsService.updateStatut(organisationId, StatutOrganisation.CLOS).then(function (response) {
        if ('statusCode' in response || !response.data) {
          return Promise.reject(response);
        }
        if (response.data) {
          window.location.href = `/`;
        }
      });
    }
  };

  return (
    <Layout>
      <CloseAlert
        alertOpen={closeAlertOpen}
        setAlertOpen={setCloseAlertOpen}
        title={intl.formatMessage({ id: 'organisation.close.alert.title' })}
        content={intl.formatMessage({ id: 'organisation.close.alert.statut' })}
        closeProjet={closeOrganisation}
      />
      <Box
        sx={{
          display: 'flex',
          mx: '2rem',
        }}
      >
        <Typography alignSelf="center" variant="h1">
          {organisationId ? (
            <FormattedMessage id="organisation.edit.title" />
          ) : (
            <FormattedMessage id="organisation.create.title" />
          )}
        </Typography>
      </Box>
      <Container sx={{ mt: '2rem' }}>
        <Paper sx={{ mx: '2rem', mb: '2rem', p: '2rem' }}>
          <Stack>
            <TextField
              id={`organisation-name`}
              label={intl.formatMessage({ id: 'organisation.edit.name' })}
              value={organisation.nom}
              onChange={(e) => {
                setEnableSave(true);
                setOrganisation({ ...organisation, nom: e.target.value });
              }}
              sx={{ width: '50%', marginRight: 10 }}
            />
            <Stack display="flex" flexDirection="row" alignItems="center" sx={{ margin: '2rem' }}>
              <Box
                sx={{
                  maxHeight: 150,
                  minHeight: 150,
                  maxWidth: 150,
                  minWidth: 150,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '5px double',
                  borderRadius: '0.5rem',
                  color: 'primary.main',
                  backgroundColor: 'white',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 100,
                    maxHeight: 100,
                  }}
                >
                  <label htmlFor="contained-button-file">
                    <input
                      onChange={onImageChange}
                      style={{ display: 'none' }}
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      ref={hiddenFileInput}
                    />
                    {(!organisation.logo || organisation.logo.length === 0) && <NoLogo size="large" />}
                    {organisation.logo && organisation.logo.length > 0 && (
                      <img src={`${organisation.logo}`} alt="logo" width={100} />
                    )}
                  </label>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <label htmlFor="contained-button-file">
                  <input
                    onChange={onImageChange}
                    style={{ display: 'none' }}
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    ref={hiddenFileInput}
                  />
                  <Button variant="outlined" onClick={handleClick} sx={{ ml: '2rem', maxWidth: 120, minWidth: 120 }}>
                    {!organisation.logo || organisation.logo.length === 0
                      ? intl.formatMessage({ id: 'organisation.edit.addLogo' })
                      : intl.formatMessage({ id: 'organisation.edit.upload' })}
                  </Button>
                </label>
              </Box>
            </Stack>
          </Stack>
        </Paper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mx: '2rem' }}>
          <Button
            onClick={saveOrganisation}
            disabled={!enableSave || organisation.nom === ''}
            variant="contained"
            sx={{ width: '15rem' }}
          >
            {intl.formatMessage({ id: 'questionnaire.save' })}
          </Button>

          <Button
            onClick={() => setCloseAlertOpen(true)}
            disabled={organisation.statut === StatutOrganisation.CLOS}
            variant="outlined"
            startIcon={<CancelOutlinedIcon />}
            sx={{ width: '15rem' }}
          >
            {intl.formatMessage({ id: 'organisation.close.button' })}
          </Button>
        </Box>
      </Container>
    </Layout>
  );
};
