import { MatrixItem, MatrixItemWithLabelDetail, MatrixLabelPosition } from '@shared/src/api/matrix/dto/matrix.dto';

export const adjustPointPositions = (
  points: MatrixItem[],
  maxDataX: number,
  currentIndex: number = 0,
): MatrixItem[] => {
  const adjustedPoints: MatrixItem[] = [...points];
  const pointToAdjust = adjustedPoints[currentIndex];

  // Vérifier si le point a des coordonnées en conflit avec d'autres points
  const conflicts = adjustedPoints.some(
    (existingPoint, index) =>
      index !== currentIndex && existingPoint.x === pointToAdjust.x && existingPoint.y === pointToAdjust.y,
  );

  // Si un conflit est détecté, ajuster les coordonnées du point
  if (conflicts) {
    if (pointToAdjust.x >= maxDataX) {
      pointToAdjust.y += 0.06;
    } else {
      pointToAdjust.x += 0.03;
    }
    return adjustPointPositions(adjustedPoints, maxDataX, currentIndex); // Rappeler la fonction pour vérifier à nouveau
  }

  if (currentIndex < adjustedPoints.length - 1) {
    return adjustPointPositions(adjustedPoints, maxDataX, currentIndex + 1); // Passer au point suivant
  }

  return adjustedPoints;
};

export const adjustPointsWithMargin = (pointsWithLabelCoords: MatrixItemWithLabelDetail[], topMargin: number) => {
  if (topMargin === 0) return pointsWithLabelCoords;

  return pointsWithLabelCoords.map((point) => {
    const { labelDetail } = point;
    const { labelY } = labelDetail;
    return {
      ...point,
      labelDetail: {
        ...labelDetail,
        labelY: labelY - topMargin,
      },
    };
  });
};

export const calculateTopLabelY = (pointsWithLabelCoords: MatrixItemWithLabelDetail[]): number => {
  let topLabelY = 0;

  pointsWithLabelCoords.forEach(({ labelDetail }) => {
    const { labelY } = labelDetail;
    // top labels are more and more negative
    if (labelY < topLabelY) topLabelY = labelY;
  });
  return topLabelY - 10;
};

const zonesSeSuperposent = (
  ax: number,
  ay: number,
  bx: number,
  by: number,
  awidth: number,
  bwidth: number,
  aheigt: number,
  bheigt: number,
): boolean => {
  const margin = 10;
  // Calcul des coordonnées des bords des deux zones
  const maxaX = ax + awidth + margin;
  const maxaY = ay + aheigt + margin;
  const maxbX = bx + bwidth + margin;
  const maxbY = by + bheigt + margin;

  return maxaX >= bx && ax <= maxbX && ay <= maxbY && maxaY >= by;
};

/**
 * Recursive function : provides a label position which does not conflict with other labels.
 */
export const getLabelPosition = (
  labelPositions: MatrixLabelPosition[],
  x: number,
  y: number,
  name: string,
  witdh: number,
  heigt: number,
): { x: number; y: number } => {
  const adjustedPoint = { x, y };
  const xAdjustment: number = 0;
  const yAdjustment: number = -10;
  labelPositions.forEach(({ labelValue, x, y, lineWidthMax, lineHeigtMax }) => {
    if (
      labelValue !== name &&
      zonesSeSuperposent(adjustedPoint.x, adjustedPoint.y, x, y, witdh, lineWidthMax, heigt, lineHeigtMax)
    ) {
      const { x: newX, y: newY } = getLabelPosition(
        labelPositions,
        adjustedPoint.x + xAdjustment,
        adjustedPoint.y + yAdjustment,
        name,
        witdh,
        heigt,
      );

      adjustedPoint.y = newY;
      adjustedPoint.x = newX;
    }
  });

  return { x: adjustedPoint.x, y: adjustedPoint.y };
};

export const splitStringEquallyIn2Lines = (str: string): string[] => {
  const words = str.split(' ');
  const numWordsPerLine = Math.ceil(words.length / 2);
  const lines: string[] = [];

  for (let i = 0; i < 2; i++) {
    const start = i * numWordsPerLine;
    const end = start + numWordsPerLine;
    const lineWords = words.slice(start, end);
    if (lineWords.length !== 0) {
      lines.push(lineWords.join(' '));
    }
  }

  return lines;
};
