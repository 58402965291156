import { Stack } from '@mui/material';
import { EndComponent } from '@shared/src/components/questionnaire/End/EndComponent';

export function EndPage() {
  return (
    <Stack sx={{ margin: 4 }}>
      <EndComponent />
    </Stack>
  );
}
