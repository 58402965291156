import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { CreateSousSujetDto } from '@shared/src/api/sous-sujet/dto/create-sous-sujet.dto';
import { SousSujetDto } from '@shared/src/api/sous-sujet/dto/sous-sujet.dto';
import { SujetDto } from '@shared/src/api/sujet/dto/sujet.dto';
import MarkdownEditor from '@shared/src/components/MarkdownEditor';
import { useLang } from '@shared/src/components/providers/LangProvider';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { useUtilisateur } from '@shared/src/components/providers/UtilisateurProvider';
import { StatutProjet } from '@shared/src/enum/projet.enum';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { sousSujetsService } from '@shared/src/services/SousSujetsService';
import { sujetsService } from '@shared/src/services/SujetsService';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

export function SubsubjectsTab() {
  const { projetId } = useParams();
  const intl = useIntl();
  const { lang } = useLang();
  const { projet } = useProjet();
  const { utilisateur } = useUtilisateur();
  const urlParams = new URLSearchParams(window.location.search);
  const [subsubjects, setSubsubjects] = useState<SousSujetDto[]>([]);
  const [sujets, setSujets] = useState<SujetDto[]>([]);
  const [sujetId, setSujetId] = useState<string>(urlParams.get('sujet') ?? '');
  const [showCreateSubsubject, setShowCreateSubsubject] = useState<boolean>(false);
  const [newSubsubject, setNewSubsubject] = useState<CreateSousSujetDto>({
    nom: {},
    position: 0,
  });
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    sujetsService
      .findAll()
      .then(function (response) {
        if ('statusCode' in response) {
          return Promise.reject(response);
        }
        if (!sujetId) setSujetId(response.data[0].id);
        setSujets(response.data);
      })
      .catch(function (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      });
  }, [projetId]);

  useEffect(() => {
    if (sujetId) {
      setLoading(true);
      sousSujetsService
        .findAll(sujetId)
        .then(function (response) {
          if ('statusCode' in response) {
            return Promise.reject(response);
          }
          setSubsubjects(response.data);
          setNewSubsubject({ ...newSubsubject, position: response.data.length + 1 });
          setLoading(false);
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          console.error(error);
        });
    }
  }, [sujetId]);

  const updateSubsubjects = (e: React.FormEvent) => {
    e.preventDefault();
    if (sujetId) {
      subsubjects.forEach((subsubject) => {
        sousSujetsService.update(sujetId, subsubject.id, {
          nom: subsubject.nom,
          nomCourt: subsubject.nomCourt ?? undefined,
          description: subsubject.description ?? undefined,
        });
      });
      setSnackbarMessage(intl.formatMessage({ id: 'questionnaire.subsubjects.update' }));
      setOpenSnackbar(true);
    }
  };

  const createSubsubject = () => {
    sousSujetsService.create(sujetId, newSubsubject).then((response) => {
      if ('statusCode' in response) {
        return Promise.reject(response);
      } else {
        const newSubsubjectList = subsubjects;
        if (response?.data) {
          newSubsubjectList.push(response.data);
        }
        setSubsubjects(newSubsubjectList);
        setShowCreateSubsubject(false);
        setNewSubsubject({
          nom: {},
          position: subsubjects.length + 1,
        });
        setSnackbarMessage(intl.formatMessage({ id: 'questionnaire.subsubjects.create' }));
        setOpenSnackbar(true);
      }
    });
  };

  const deleteSubsubject = (id: string) => {
    sousSujetsService.delete(sujetId, id).then((response) => {
      if ('statusCode' in response) {
        return Promise.reject(response);
      } else {
        const newSubsubjectList = subsubjects.filter((el) => el.id !== id);
        setSubsubjects(newSubsubjectList);
        setSnackbarMessage(intl.formatMessage({ id: 'questionnaire.subsubjects.delete' }));
        setOpenSnackbar(true);
      }
    });
  };

  if (utilisateur?.role === UtilisateurRole.TENZING && projet?.statut === StatutProjet.CREE) {
    return (
      <Stack component="form" onSubmit={updateSubsubjects}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            setOpenSnackbar(false);
            setSnackbarMessage('');
          }}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Paper sx={{ mb: '2rem', p: '2rem' }}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="subject-choice-label">
              {intl.formatMessage({ id: 'questionnaire.subjects.choice' })}
            </InputLabel>
            <Select
              id="subject-choice"
              labelId="subject-choice-label"
              value={sujetId}
              label={intl.formatMessage({ id: 'questionnaire.subjects.choice' })}
              onChange={(e) => {
                setSujetId(e.target.value);
              }}
            >
              {sujets.map((sujet) => (
                <MenuItem key={sujet.nom[lang]} value={sujet.id}>
                  {sujet.nom[lang]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            {!loading &&
              subsubjects.map((subsubject, index) => (
                <Grid container sx={{ alignItems: 'center', pt: '3rem' }} key={subsubject.id}>
                  <Grid container item xs={subsubject.globalId ? 12 : 11} spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-basic"
                        label={intl.formatMessage({ id: 'questionnaire.name.fr' })}
                        defaultValue={subsubject.nom.fr}
                        autoComplete="no"
                        onChange={(e) => {
                          subsubject.nom.fr = e.target.value;
                        }}
                        sx={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-basic"
                        label={intl.formatMessage({ id: 'questionnaire.name.en' })}
                        defaultValue={subsubject.nom.en}
                        autoComplete="no"
                        onChange={(e) => {
                          subsubject.nom.en = e.target.value;
                        }}
                        sx={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-basic"
                        label={intl.formatMessage({ id: 'questionnaire.short-name.fr' })}
                        defaultValue={subsubject.nomCourt?.fr}
                        autoComplete="no"
                        size="small"
                        onChange={(e) => {
                          subsubject.nomCourt = { ...subsubject.nomCourt, fr: e.target.value };
                        }}
                        sx={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-basic"
                        label={intl.formatMessage({ id: 'questionnaire.short-name.en' })}
                        defaultValue={subsubject.nomCourt?.en}
                        autoComplete="no"
                        size="small"
                        onChange={(e) => {
                          subsubject.nomCourt = { ...subsubject.nomCourt, en: e.target.value };
                        }}
                        sx={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MarkdownEditor
                        title={intl.formatMessage({ id: 'questionnaire.description.fr' })}
                        value={subsubject.description?.fr ?? ''}
                        setValue={(value: string) =>
                          (subsubject.description = { ...subsubject.description, fr: value })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MarkdownEditor
                        title={intl.formatMessage({ id: 'questionnaire.description.en' })}
                        value={subsubject.description?.en ?? ''}
                        setValue={(value: string) =>
                          (subsubject.description = { ...subsubject.description, en: value })
                        }
                      />
                    </Grid>
                  </Grid>
                  {!subsubject.globalId && (
                    <Grid item xs={1} sx={{ height: '100%', alignItems: 'center' }}>
                      <IconButton
                        aria-label={intl.formatMessage({ id: 'matrix.filter.title' })}
                        onClick={() => deleteSubsubject(subsubject.id)}
                        color="primary"
                        size="large"
                        sx={{ maxHeight: 70, minHeight: 70 }}
                      >
                        <DeleteOutlineOutlinedIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {index + 1 !== subsubjects.length && (
                      <Divider sx={{ mt: '2rem', backgroundColor: 'primary.main' }} />
                    )}
                  </Grid>
                </Grid>
              ))}
            {showCreateSubsubject ? (
              <>
                <Grid container spacing={2} key="new-subsubject" sx={{ pt: '3rem' }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-basic"
                      label={intl.formatMessage({ id: 'questionnaire.name.fr' })}
                      multiline
                      onChange={(e) => {
                        setNewSubsubject({ ...newSubsubject, nom: { ...newSubsubject.nom, fr: e.target.value } });
                      }}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-basic"
                      label={intl.formatMessage({ id: 'questionnaire.name.en' })}
                      multiline
                      onChange={(e) => {
                        setNewSubsubject({ ...newSubsubject, nom: { ...newSubsubject.nom, en: e.target.value } });
                      }}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-basic"
                      label={intl.formatMessage({ id: 'questionnaire.short-name.fr' })}
                      size="small"
                      onChange={(e) => {
                        setNewSubsubject({
                          ...newSubsubject,
                          nomCourt: { ...newSubsubject.nomCourt, fr: e.target.value },
                        });
                      }}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-basic"
                      label={intl.formatMessage({ id: 'questionnaire.short-name.en' })}
                      size="small"
                      onChange={(e) => {
                        setNewSubsubject({
                          ...newSubsubject,
                          nomCourt: { ...newSubsubject.nomCourt, en: e.target.value },
                        });
                      }}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MarkdownEditor
                      title={intl.formatMessage({ id: 'questionnaire.description.fr' })}
                      value={newSubsubject.description?.fr ?? ''}
                      setValue={(value: string) =>
                        setNewSubsubject({
                          ...newSubsubject,
                          description: { ...newSubsubject.description, fr: value },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MarkdownEditor
                      title={intl.formatMessage({ id: 'questionnaire.description.en' })}
                      value={newSubsubject.description?.en ?? ''}
                      setValue={(value: string) =>
                        setNewSubsubject({
                          ...newSubsubject,
                          description: { ...newSubsubject.description, en: value },
                        })
                      }
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    pt: '2rem',
                  }}
                >
                  <Button onClick={createSubsubject} variant="outlined" sx={{ mx: '0.5rem' }}>
                    {intl.formatMessage({ id: 'questionnaire.create-subsubject' })}
                  </Button>
                  <Button onClick={() => setShowCreateSubsubject(false)} variant="outlined" sx={{ mx: '0.5rem' }}>
                    {intl.formatMessage({ id: 'questionnaire.cancel-subsubject' })}
                  </Button>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  pt: '3rem',
                }}
              >
                <Button onClick={() => setShowCreateSubsubject(true)} variant="outlined" sx={{ width: '15rem' }}>
                  {intl.formatMessage({ id: 'questionnaire.add-subsubject' })}
                </Button>
              </Box>
            )}
          </FormControl>
        </Paper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button type="submit" variant="contained" disabled={showCreateSubsubject} sx={{ width: '15rem' }}>
            {intl.formatMessage({ id: 'questionnaire.save' })}
          </Button>
        </Box>
      </Stack>
    );
  } else {
    return (
      <TableContainer component={Paper} sx={{ borderRadius: '1rem', padding: '1rem' }}>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="subject-choice-label">
            {intl.formatMessage({ id: 'questionnaire.subjects.choice' })}
          </InputLabel>
          <Select
            id="subject-choice"
            labelId="subject-choice-label"
            value={sujetId}
            label={intl.formatMessage({ id: 'questionnaire.subjects.choice' })}
            onChange={(e) => {
              setSujetId(e.target.value);
            }}
          >
            {sujets.map((sujet) => (
              <MenuItem key={sujet.nom[lang]} value={sujet.id}>
                {sujet.nom[lang]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Table stickyHeader>
          <colgroup>
            <col style={{ width: '50%' }} />
            <col style={{ width: '50%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'questionnaire.name.fr' })}
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'questionnaire.name.en' })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subsubjects.map((subsubject) => (
              <TableRow key={subsubject.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" width="50%">
                  {subsubject.nom.fr || '/'}
                </TableCell>
                <TableCell component="th" align="left">
                  {subsubject.nom.en || '/'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
