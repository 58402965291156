import { Box, Button, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        marginTop: 'auto',
        pl: { md: '12rem' }, // pour le menu
        backgroundColor: 'primary.light',
        width: '100%',
      }}
    >
      <Grid justifyContent="space-around" container>
        <Button variant="text" href="/legal-information">
          <FormattedMessage id="footer.links.legal-information" />
        </Button>
        <Button variant="text" href="/privacy-policy">
          <FormattedMessage id="footer.links.privacy-policy" />
        </Button>
        <Button variant="text" href="/cookie-policy">
          <FormattedMessage id="footer.links.cookies-policy" />
        </Button>
      </Grid>
    </Box>
  );
};
