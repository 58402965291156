import { QuestionnairePage } from '../../pages/projets/QuestionnairePage';
import { SubjectsTab } from './SubjectsTab';

export function SubjectsPage() {
  return (
    <QuestionnairePage>
      <SubjectsTab />
    </QuestionnairePage>
  );
}
