import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import LogoutAlert from '@shared/src/components/LogoutAlert';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import React, { PropsWithChildren, useState } from 'react';
import { DrawerContent } from './DrawerContent';
import { OrganisationBanner } from './OrganisationBanner';

type Props = {
  connected?: boolean;
};

const HeaderLayout: React.FC<PropsWithChildren<Props>> = ({ connected = true }) => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { organisation } = useProjet();

  return (
    <>
      <LogoutAlert alertOpen={alertOpen} setAlertOpen={setAlertOpen} />

      {/* Version WEB */}
      {organisation && <OrganisationBanner organisation={organisation} />}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'unset' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '12rem', justifyContent: 'space-between' },
        }}
        open
      >
        <DrawerContent connected={connected} setAlertOpen={setAlertOpen} />
      </Drawer>

      {/* Version Mobile */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          size="large"
          sx={{ display: { md: 'none' }, position: 'absolute', right: 0 }}
          onClick={() => setMenuOpen(true)}
        >
          <MenuIcon />
        </IconButton>
      </Box>

      <Drawer
        anchor="right"
        variant="temporary" // mobile version
        sx={{
          display: { md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '12rem', justifyContent: 'space-between' },
        }}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <DrawerContent connected={connected} setAlertOpen={setAlertOpen} />
      </Drawer>
    </>
  );
};

export default HeaderLayout;
