import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { CategoriePartiePrenanteDto } from '@shared/src/api/categorie-partie-prenante/dto/categorie-partie-prenante.dto';
import { SujetDto } from '@shared/src/api/sujet/dto/sujet.dto';
import { useLang } from '@shared/src/components/providers/LangProvider';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { useUtilisateur } from '@shared/src/components/providers/UtilisateurProvider';
import { StatutProjet } from '@shared/src/enum/projet.enum';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { categoriePartiePrenantesService } from '@shared/src/services/CategoriePartiePrenantesService';
import { ServerResponse } from '@shared/src/services/ServerResponse';
import { sujetsService } from '@shared/src/services/SujetsService';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type PanelProps = {
  sujets: SujetDto[];
  categorie: CategoriePartiePrenanteDto;
  setSujets: (sujets: SujetDto[]) => void;
};

const SujetPanel: React.FC<PropsWithChildren<PanelProps>> = ({ sujets, categorie, setSujets }) => {
  const { lang } = useLang();
  const { projet } = useProjet();
  const { utilisateur } = useUtilisateur();
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (value: boolean, sujet: SujetDto, categorie: CategoriePartiePrenanteDto) => {
    if (value) {
      const categoriePartiesPrenantesList = sujet.categoriePartiePrenantes.map((c) => c.id);
      categoriePartiesPrenantesList.push(categorie.id);
      sujetsService
        .update(sujet.id, {
          categorieIds: categoriePartiesPrenantesList,
        })
        .then((res: ServerResponse<SujetDto | null>) => {
          if ('data' in res) {
            const newSujetsList = sujets.map((sujet) => {
              if (res.data?.id === sujet.id) {
                return res.data;
              } else {
                return sujet;
              }
            });
            setSujets(newSujetsList);
          }
        });
    } else {
      sujetsService
        .update(sujet.id, {
          categorieIds: sujet.categoriePartiePrenantes.filter((c) => c.id !== categorie.id).map((c) => c.id),
        })
        .then((res: ServerResponse<SujetDto | null>) => {
          if ('data' in res) {
            const newSujetsList = sujets.map((sujet) => {
              if (res.data?.id === sujet.id) {
                return res.data;
              } else {
                return sujet;
              }
            });
            setSujets(newSujetsList);
          }
        });
    }
  };

  return (
    <Accordion
      elevation={0}
      expanded={open}
      onChange={() => setOpen(!open)}
      sx={{
        my: '0.5rem',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>{categorie.nom[lang]}</AccordionSummary>
      <AccordionDetails>
        {utilisateur?.role === UtilisateurRole.TENZING && projet?.statut === StatutProjet.CREE && (
          <FormGroup>
            {sujets.map((sujet) => (
              <FormControlLabel
                key={sujet.id}
                control={
                  <Checkbox
                    key={sujet.id}
                    onChange={(e) => handleChange(e.target.checked, sujet, categorie)}
                    checked={sujet.categoriePartiePrenantes.some((c) => c.id === categorie.id)}
                  />
                }
                label={sujet.nom[lang]}
              />
            ))}
          </FormGroup>
        )}
        {(utilisateur?.role === UtilisateurRole.CLIENT ||
          (utilisateur?.role === UtilisateurRole.TENZING && projet?.statut !== StatutProjet.CREE)) && (
          <List
            sx={{
              listStyleType: 'disc',
              pl: 4,
            }}
          >
            {sujets
              .filter((sujet) => sujet.categoriePartiePrenantes.some((c) => c.id === categorie.id))
              .map((sujet) => (
                <ListItem sx={{ display: 'list-item', pl: 2 }} key={sujet.id}>
                  <Typography key={sujet.id}>{sujet.nom[lang]}</Typography>
                </ListItem>
              ))}
          </List>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export function MatchingTab() {
  const { projetId } = useParams();
  const [categories, setCategories] = useState<CategoriePartiePrenanteDto[]>([]);
  const [sujets, setSujets] = useState<SujetDto[]>([]);
  const { lang } = useLang();

  useEffect(() => {
    categoriePartiePrenantesService
      .findAll()
      .then(function (response) {
        if ('statusCode' in response) {
          return Promise.reject(response);
        }
        setCategories(response.data.sort((a, b) => ((a.nom[lang] ?? '') < (b.nom[lang] ?? '') ? -1 : 1)));
      })
      .catch(function (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      });

    sujetsService
      .findAll()
      .then(function (response) {
        if ('statusCode' in response) {
          return Promise.reject(response);
        }
        setSujets(response.data.sort((a, b) => ((a.nom[lang] ?? '') < (b.nom[lang] ?? '') ? -1 : 1)));
      })
      .catch(function (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      });
  }, [projetId]);

  return (
    <FormControl sx={{ width: '100%' }}>
      {categories &&
        categories.map((categorie) => (
          <SujetPanel key={categorie.id} sujets={sujets} categorie={categorie} setSujets={setSujets} />
        ))}
    </FormControl>
  );
}
