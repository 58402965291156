import { GlobalSettingsPage } from '../../pages/global-settings/GlobalSettings';
import { MatchingTab } from './MatchingTab';

export function MatchingGlobal() {
  return (
    <GlobalSettingsPage>
      <MatchingTab />
    </GlobalSettingsPage>
  );
}
