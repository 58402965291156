import { LabelDisplayedRowsArgs } from '@mui/material';
import { GridLocaleText } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';

export function CustomGridLabels(): Partial<GridLocaleText> {
  const intl = useIntl();

  return {
    // Root
    noRowsLabel: intl.formatMessage({ id: 'dataGrid.noRowsLabel' }),
    noResultsOverlayLabel: intl.formatMessage({ id: 'dataGrid.noResultsOverlayLabel' }),

    // Density selector toolbar button text
    toolbarDensity: intl.formatMessage({ id: 'dataGrid.toolbarDensity' }),
    toolbarDensityLabel: intl.formatMessage({ id: 'dataGrid.toolbarDensityLabel' }),
    toolbarDensityCompact: intl.formatMessage({ id: 'dataGrid.toolbarDensityCompact' }),
    toolbarDensityStandard: intl.formatMessage({ id: 'dataGrid.toolbarDensityStandard' }),
    toolbarDensityComfortable: intl.formatMessage({ id: 'dataGrid.toolbarDensityComfortable' }),

    // Columns selector toolbar button text
    toolbarColumns: intl.formatMessage({ id: 'dataGrid.toolbarColumns' }),
    toolbarColumnsLabel: intl.formatMessage({ id: 'dataGrid.toolbarColumnsLabel' }),

    // Filters toolbar button text
    toolbarFilters: intl.formatMessage({ id: 'dataGrid.toolbarFilters' }),
    toolbarFiltersLabel: intl.formatMessage({ id: 'dataGrid.toolbarFiltersLabel' }),
    toolbarFiltersTooltipHide: intl.formatMessage({ id: 'dataGrid.toolbarFiltersTooltipHide' }),
    toolbarFiltersTooltipShow: intl.formatMessage({ id: 'dataGrid.toolbarFiltersTooltipShow' }),
    toolbarFiltersTooltipActive: (count) =>
      count !== 1
        ? `${count} ${intl.formatMessage({ id: 'dataGrid.toolbarFiltersTooltipActiveSeveralFilters' })}`
        : `${count} ${intl.formatMessage({ id: 'dataGrid.toolbarFiltersTooltipActiveOneFilter' })}`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: intl.formatMessage({ id: 'dataGrid.toolbarQuickFilterPlaceholder' }),
    toolbarQuickFilterLabel: intl.formatMessage({ id: 'dataGrid.toolbarQuickFilterLabel' }),
    toolbarQuickFilterDeleteIconLabel: intl.formatMessage({ id: 'dataGrid.toolbarQuickFilterDeleteIconLabel' }),

    // Export selector toolbar button text
    toolbarExport: intl.formatMessage({ id: 'dataGrid.toolbarExport' }),
    toolbarExportLabel: intl.formatMessage({ id: 'dataGrid.toolbarExportLabel' }),
    toolbarExportCSV: intl.formatMessage({ id: 'dataGrid.toolbarExportCSV' }),
    toolbarExportPrint: intl.formatMessage({ id: 'dataGrid.toolbarExportPrint' }),
    toolbarExportExcel: intl.formatMessage({ id: 'dataGrid.toolbarExportExcel' }),

    // Columns panel text
    columnsPanelTextFieldLabel: intl.formatMessage({ id: 'dataGrid.columnsPanelTextFieldLabel' }),
    columnsPanelTextFieldPlaceholder: intl.formatMessage({ id: 'dataGrid.columnsPanelTextFieldPlaceholder' }),
    columnsPanelDragIconLabel: intl.formatMessage({ id: 'dataGrid.columnsPanelDragIconLabel' }),
    columnsPanelShowAllButton: intl.formatMessage({ id: 'dataGrid.columnsPanelShowAllButton' }),
    columnsPanelHideAllButton: intl.formatMessage({ id: 'dataGrid.columnsPanelHideAllButton' }),

    // Filter panel text
    filterPanelOperator: intl.formatMessage({ id: 'dataGrid.filterPanelOperator' }),
    filterPanelAddFilter: intl.formatMessage({ id: 'dataGrid.filterPanelAddFilter' }),
    filterPanelDeleteIconLabel: intl.formatMessage({ id: 'dataGrid.filterPanelDeleteIconLabel' }),
    filterPanelOperatorAnd: intl.formatMessage({ id: 'dataGrid.filterPanelOperatorAnd' }),
    filterPanelOperatorOr: intl.formatMessage({ id: 'dataGrid.filterPanelOperatorOr' }),
    filterPanelColumns: intl.formatMessage({ id: 'dataGrid.filterPanelColumns' }),
    filterPanelInputLabel: intl.formatMessage({ id: 'dataGrid.filterPanelInputLabel' }),
    filterPanelInputPlaceholder: intl.formatMessage({ id: 'dataGrid.filterPanelInputPlaceholder' }),

    // Filter operators text
    filterOperatorContains: intl.formatMessage({ id: 'dataGrid.filterOperatorContains' }),
    filterOperatorEquals: intl.formatMessage({ id: 'dataGrid.filterOperatorEquals' }),
    filterOperatorStartsWith: intl.formatMessage({ id: 'dataGrid.filterOperatorStartsWith' }),
    filterOperatorEndsWith: intl.formatMessage({ id: 'dataGrid.filterOperatorEndsWith' }),
    filterOperatorIs: intl.formatMessage({ id: 'dataGrid.filterOperatorIs' }),
    filterOperatorNot: intl.formatMessage({ id: 'dataGrid.filterOperatorNot' }),
    filterOperatorAfter: intl.formatMessage({ id: 'dataGrid.filterOperatorAfter' }),
    filterOperatorBefore: intl.formatMessage({ id: 'dataGrid.filterOperatorBefore' }),
    filterOperatorIsEmpty: intl.formatMessage({ id: 'dataGrid.filterOperatorIsEmpty' }),
    filterOperatorIsNotEmpty: intl.formatMessage({ id: 'dataGrid.filterOperatorIsNotEmpty' }),
    filterOperatorIsAnyOf: intl.formatMessage({ id: 'dataGrid.filterOperatorIsAnyOf' }),

    // Column menu text
    columnMenuLabel: intl.formatMessage({ id: 'dataGrid.columnMenuLabel' }),
    columnMenuShowColumns: intl.formatMessage({ id: 'dataGrid.columnMenuShowColumns' }),
    columnMenuFilter: intl.formatMessage({ id: 'dataGrid.columnMenuFilter' }),
    columnMenuHideColumn: intl.formatMessage({ id: 'dataGrid.columnMenuHideColumn' }),
    columnMenuUnsort: intl.formatMessage({ id: 'dataGrid.columnMenuUnsort' }),
    columnMenuSortAsc: intl.formatMessage({ id: 'dataGrid.columnMenuSortAsc' }),
    columnMenuSortDesc: intl.formatMessage({ id: 'dataGrid.columnMenuSortDesc' }),

    // Checkbox selection
    checkboxSelectionHeaderName: intl.formatMessage({ id: 'dataGrid.checkboxSelectionHeaderName' }),

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
      count !== 1
        ? `${count} ${intl.formatMessage({ id: 'dataGrid.columnHeaderFiltersTooltipActiveSeveralFilters' })}`
        : `${count} ${intl.formatMessage({ id: 'dataGrid.columnHeaderFiltersTooltipActiveOneFilter' })}`,
    columnHeaderFiltersLabel: intl.formatMessage({ id: 'dataGrid.columnHeaderFiltersLabel' }),
    columnHeaderSortIconLabel: intl.formatMessage({ id: 'dataGrid.columnHeaderSortIconLabel' }),

    // Rows selected footer text
    footerRowSelected: (count) =>
      count !== 1
        ? `${count.toLocaleString()} ${intl.formatMessage({ id: 'dataGrid.footerRowSelectedSeveralRows' })}`
        : `${count.toLocaleString()} ${intl.formatMessage({ id: 'dataGrid.footerRowSelectedOneRow' })}`,

    // Pagination
    MuiTablePagination: {
      labelDisplayedRows: (paginationInfo: LabelDisplayedRowsArgs) =>
        `${paginationInfo.from.toLocaleString()}-${paginationInfo.to.toLocaleString()} ${intl.formatMessage({
          id: 'dataGrid.footerTotalVisibleRows',
        })} ${paginationInfo.count.toLocaleString()}`,
      labelRowsPerPage: intl.formatMessage({ id: 'dataGrid.rowsPerPage' }),
    },
  };
}
