import { Stack } from '@mui/material';
import { ParcoursPartiePrenanteDto } from '@shared/src/api/parcours-partie-prenante/dto/parcours-partie-prenante.dto';
import { LastQuestionComponent } from '@shared/src/components/questionnaire/End/LastQuestionComponent';
import { useParams } from 'react-router-dom';

export type LastQuestionPageProps = {
  parcours: ParcoursPartiePrenanteDto | null;
};

export function LastQuestionPage(props: LastQuestionPageProps) {
  const { parcours } = props;
  const { organisationId, projetId, partiePrenanteId } = useParams();

  if (!parcours) {
    return null;
  }

  return (
    <Stack sx={{ margin: 4 }}>
      <LastQuestionComponent
        nextStepUrl={`/${organisationId}/projets/${projetId}/stakeholders/listing/${partiePrenanteId}/end`}
        parcours={parcours}
      />
    </Stack>
  );
}
