import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { SujetDto } from '@shared/src/api/sujet/dto/sujet.dto';
import MarkdownEditor from '@shared/src/components/MarkdownEditor';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { useUtilisateur } from '@shared/src/components/providers/UtilisateurProvider';
import { StatutProjet } from '@shared/src/enum/projet.enum';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { sujetsService } from '@shared/src/services/SujetsService';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

export function SubjectsTab() {
  const { projetId } = useParams();
  const intl = useIntl();
  const { projet } = useProjet();
  const { utilisateur } = useUtilisateur();
  const [sujets, setSujets] = useState<SujetDto[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  useEffect(() => {
    sujetsService
      .findAll()
      .then(function (response) {
        if ('statusCode' in response) {
          return Promise.reject(response);
        }
        setSujets(response.data);
      })
      .catch(function (error) {
        if (error.name === 'AbortError') return;
        console.error(error);
      });
  }, [projetId]);

  const updateSubjects = (e: React.FormEvent) => {
    e.preventDefault();
    sujets.forEach((sujet) => {
      sujetsService.update(sujet.id, {
        nom: sujet.nom,
        nomCourt: sujet.nomCourt ?? undefined,
        description: sujet.description ?? undefined,
      });
    });
    setSnackbarMessage(intl.formatMessage({ id: 'questionnaire.subjects.update' }));
    setOpenSnackbar(true);
  };

  if (utilisateur?.role === UtilisateurRole.TENZING && projet?.statut === StatutProjet.CREE) {
    return (
      <Stack component="form" onSubmit={updateSubjects}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            setOpenSnackbar(false);
            setSnackbarMessage('');
          }}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Paper sx={{ mb: '2rem' }}>
          <FormControl sx={{ width: '100%' }}>
            {sujets.map((sujet, index) => (
              <Grid container spacing={2} key={sujet.id} sx={{ px: '2rem', pt: '2rem', pb: '1rem' }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-basic"
                    label={intl.formatMessage({ id: 'questionnaire.name.fr' })}
                    defaultValue={sujet.nom.fr}
                    required
                    autoComplete="no"
                    onChange={(e) => {
                      sujet.nom.fr = e.target.value;
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-basic"
                    label={intl.formatMessage({ id: 'questionnaire.name.en' })}
                    defaultValue={sujet.nom.en}
                    required
                    autoComplete="no"
                    onChange={(e) => {
                      sujet.nom.en = e.target.value;
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-basic"
                    label={intl.formatMessage({ id: 'questionnaire.short-name.fr' })}
                    defaultValue={sujet.nomCourt?.fr}
                    autoComplete="no"
                    size="small"
                    onChange={(e) => {
                      sujet.nomCourt = { ...sujet.nomCourt, fr: e.target.value };
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-basic"
                    label={intl.formatMessage({ id: 'questionnaire.short-name.en' })}
                    defaultValue={sujet.nomCourt?.en}
                    autoComplete="no"
                    size="small"
                    onChange={(e) => {
                      sujet.nomCourt = { ...sujet.nomCourt, en: e.target.value };
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MarkdownEditor
                    title={intl.formatMessage({ id: 'questionnaire.description.fr' })}
                    value={sujet.description?.fr ?? ''}
                    setValue={(value: string) => (sujet.description = { ...sujet.description, fr: value })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MarkdownEditor
                    title={intl.formatMessage({ id: 'questionnaire.description.en' })}
                    value={sujet.description?.en ?? ''}
                    setValue={(value: string) => (sujet.description = { ...sujet.description, en: value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button endIcon={<ArrowForwardIosOutlinedIcon />} href={`question?sujet=${sujet.id}`}>
                    {intl.formatMessage({ id: 'questionnaire.subjects.show-questions' })}
                  </Button>
                  <Button endIcon={<ArrowForwardIosOutlinedIcon />} href={`sous-sujet?sujet=${sujet.id}`}>
                    {intl.formatMessage({ id: 'questionnaire.questions.show-subsubjects' })}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {index + 1 !== sujets.length && <Divider sx={{ backgroundColor: 'primary.main' }} />}
                </Grid>
              </Grid>
            ))}
          </FormControl>
        </Paper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button type="submit" variant="contained" sx={{ width: '15rem' }}>
            {intl.formatMessage({ id: 'questionnaire.save' })}
          </Button>
        </Box>
      </Stack>
    );
  } else {
    return (
      <TableContainer component={Paper} sx={{ borderRadius: '1rem', padding: '1rem' }}>
        <Table stickyHeader>
          <colgroup>
            <col style={{ width: '40%' }} />
            <col style={{ width: '40%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'questionnaire.name.fr' })}
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'questionnaire.name.en' })}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>
                {intl.formatMessage({ id: 'questionnaire.actions' })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sujets.map((sujet) => (
              <TableRow key={sujet.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" width="50%">
                  {sujet.nom.fr || '/'}
                </TableCell>
                <TableCell component="th" align="left">
                  {sujet.nom.en || '/'}
                </TableCell>
                <TableCell component="th" align="center">
                  <Button endIcon={<ArrowForwardIcon />} href={`question?sujet=${sujet.id}`} sx={{ minWidth: '13rem' }}>
                    {intl.formatMessage({ id: 'questionnaire.subjects.show-questions' })}
                  </Button>
                  <Button
                    endIcon={<ArrowForwardIcon />}
                    href={`sous-sujet?sujet=${sujet.id}`}
                    sx={{ minWidth: '13rem' }}
                  >
                    {intl.formatMessage({ id: 'questionnaire.questions.show-subsubjects' })}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
