import { GlobalSettingsPage } from '../../pages/global-settings/GlobalSettings';
import { ExplanationTab } from './ExplanationTab';

export function ExplanationGlobal() {
  return (
    <GlobalSettingsPage>
      <ExplanationTab />
    </GlobalSettingsPage>
  );
}
