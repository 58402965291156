import { Box, Card, Container, Typography } from '@mui/material';
import { ResetPasswordRequestForm } from '@shared/src/components/login/ResetPasswordRequestForm';
import { FormattedMessage } from 'react-intl';
import Layout from '../../components/Layout/Layout';

export function ResetPasswordRequestPage() {
  return (
    <Layout maxWidth="md" connected={false}>
      <Container maxWidth="sm" sx={{ height: '50vh' }}>
        <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
          <Card sx={{ p: 4, width: '100%' }}>
            <Typography variant="h4" gutterBottom align="center">
              <FormattedMessage id="reset-password.request-form.title" />
            </Typography>
            <ResetPasswordRequestForm />
          </Card>
        </Box>
      </Container>
    </Layout>
  );
}
