import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Button, Drawer, IconButton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CategoriePartiePrenanteDto } from '@shared/src/api/categorie-partie-prenante/dto/categorie-partie-prenante.dto';
import { Display, MatrixFilterProps } from '@shared/src/api/matrix/dto/matrix.dto';
import { PillierDto } from '@shared/src/api/pillier/dto/pillier.dto';
import { SujetDto } from '@shared/src/api/sujet/dto/sujet.dto';
import { FormFieldOption, FormFieldPropsEventHandler } from '@shared/src/components/form/FormFieldTypes';
import { RadioFormField } from '@shared/src/components/form/RadioFormField';
import {
  SwitchFormFieldOption,
  SwitchFormFieldPropsEventHandler,
} from '@shared/src/components/form/SwitchFormFieldTypes';
import { useLang } from '@shared/src/components/providers/LangProvider';
import { useUtilisateur } from '@shared/src/components/providers/UtilisateurProvider';
import {
  Age,
  CategorieEntreprise,
  Csp,
  Fonction,
  Genre,
  Secteur,
} from '@shared/src/enum/parcours-partie-prenante.enum';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { useAllSujets } from '@shared/src/hooks/useAllSujets';
import { useCategoriesPartiesPrenantes } from '@shared/src/hooks/useCategoriesPartiesPrenantes';
import { usePiliers } from '@shared/src/hooks/usePiliers';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { AccordionFilterElement } from './AccordionFilterElement';

const DISPLAY_OPTIONS: FormFieldOption[] = Object.entries(Display).map(function ([key, value]) {
  return { key, label: `enum.display.${key}`, value };
});

export function MatrixFilter(props: MatrixFilterProps) {
  const { drawerOpen, setDrawerOpen } = props;
  const initialFilters = props.filters;
  const { projetId } = useParams();
  const { piliers } = usePiliers();
  const { categoriesPartiesPrenantes } = useCategoriesPartiesPrenantes();
  const { sujets } = useAllSujets(projetId);
  const [expandedPanel, setExpandedPanel] = React.useState<string | false>(false);
  const intl = useIntl();
  const { lang } = useLang();
  const { utilisateur } = useUtilisateur();

  const PILIERS_OPTIONS: SwitchFormFieldOption[] =
    piliers?.map((pilier: PillierDto) => {
      return {
        key: pilier.id,
        label: pilier.nom[lang] ?? '',
        value: true,
      };
    }) || [];

  const SUJETS_OPTIONS: SwitchFormFieldOption[] =
    sujets?.map((sujet: SujetDto) => {
      const name: string | null = sujet.nomCourt?.[lang] ?? null;
      return {
        key: sujet.id,
        label: `${name && name.length > 0 ? name : sujet.nom[lang]}`,
        value: true,
      };
    }) || [];
  const CATEGORIES_PP_OPTIONS: SwitchFormFieldOption[] =
    categoriesPartiesPrenantes?.map((categorie: CategoriePartiePrenanteDto) => {
      return {
        key: categorie.id,
        label: `${categorie.nom[lang]}`,
        value: true,
      };
    }) || [];
  const GENRE_OPTIONS: SwitchFormFieldOption[] = Object.entries(Genre).map(function ([key, value]) {
    return {
      key: value,
      label: intl.formatMessage({ id: `enum.genre.${key}` }),
      value: true,
    };
  });
  const AGE_OPTIONS: SwitchFormFieldOption[] = Object.entries(Age).map(function ([key, value]) {
    return {
      key: value,
      label: intl.formatMessage({ id: `enum.age.${key}` }),
      value: true,
    };
  });

  const CSP_OPTIONS: SwitchFormFieldOption[] = Object.entries(Csp).map(function ([key, value]) {
    return {
      key: value,
      label: intl.formatMessage({ id: `enum.csp.${key}` }),
      value: true,
    };
  });

  const SECTEUR_OPTIONS: SwitchFormFieldOption[] = Object.entries(Secteur).map(function ([key, value]) {
    return {
      key: value,
      label: intl.formatMessage({ id: `enum.secteur.${key}` }),
      value: true,
    };
  });

  const FONCTION_OPTIONS: SwitchFormFieldOption[] = Object.entries(Fonction).map(function ([key, value]) {
    return {
      key: value,
      label: intl.formatMessage({ id: `enum.fonction.${key}` }),
      value: true,
    };
  });

  const CATEGORIE_ENTREPRISE_OPTIONS: SwitchFormFieldOption[] = Object.entries(CategorieEntreprise).map(function ([
    key,
    value,
  ]) {
    return {
      key: value,
      label: intl.formatMessage({ id: `enum.categorie-entreprise.${key}` }),
      value: true,
    };
  });
  const initialMatrixData = {
    display: Display.SUJET,
    piliers: { activated: false, options: PILIERS_OPTIONS },
    sujets: { options: SUJETS_OPTIONS, activated: false },
    categoriePartiePrenante: { options: CATEGORIES_PP_OPTIONS, activated: false },
    genre: { options: GENRE_OPTIONS, activated: false },
    age: { options: AGE_OPTIONS, activated: false },
    csp: { options: CSP_OPTIONS, activated: false },
    secteur: { options: SECTEUR_OPTIONS, activated: false },
    fonction: { options: FONCTION_OPTIONS, activated: false },
    categorieEntreprise: { options: CATEGORIE_ENTREPRISE_OPTIONS, activated: false },
  };
  const [matrixData, setMatrixData] = useState<{
    display: string;
    piliers: { options: SwitchFormFieldOption[]; activated: boolean };
    sujets: { options: SwitchFormFieldOption[]; activated: boolean };
    categoriePartiePrenante: { options: SwitchFormFieldOption[]; activated: boolean };
    genre: { options: SwitchFormFieldOption[]; activated: boolean };
    age: { options: SwitchFormFieldOption[]; activated: boolean };
    csp: { options: SwitchFormFieldOption[]; activated: boolean };
    secteur: { options: SwitchFormFieldOption[]; activated: boolean };
    fonction: { options: SwitchFormFieldOption[]; activated: boolean };
    categorieEntreprise: { options: SwitchFormFieldOption[]; activated: boolean };
  }>(initialMatrixData);

  useEffect(
    function () {
      if (!projetId) {
        return;
      }

      const piliers = PILIERS_OPTIONS.slice(0).map((pilier) => {
        return {
          ...pilier,
          value:
            initialFilters.piliers.length === 0 || initialFilters.piliers.findIndex((el) => el === pilier.key) !== -1,
        };
      });

      const sujets = SUJETS_OPTIONS.slice(0).map((sujet) => {
        return {
          ...sujet,
          value: initialFilters.sujets.length === 0 || initialFilters.sujets.findIndex((el) => el === sujet.key) !== -1,
        };
      });

      const categoriesPP = CATEGORIES_PP_OPTIONS.slice(0).map((categorie) => {
        return {
          ...categorie,
          value:
            initialFilters.categoriePartiePrenante.length === 0 ||
            initialFilters.categoriePartiePrenante.findIndex((el) => el === categorie.key) !== -1,
        };
      });

      const genres = GENRE_OPTIONS.slice(0).map((genre) => {
        return {
          ...genre,
          value: initialFilters.genre.length === 0 || initialFilters.genre.findIndex((el) => el === genre.key) !== -1,
        };
      });

      const ages = AGE_OPTIONS.slice(0).map((age) => {
        return {
          ...age,
          value: initialFilters.age.length === 0 || initialFilters.age.findIndex((el) => el === age.key) !== -1,
        };
      });

      const csp = CSP_OPTIONS.slice(0).map((csp) => {
        return {
          ...csp,
          value: initialFilters.csp.length === 0 || initialFilters.csp.findIndex((el) => el === csp.key) !== -1,
        };
      });

      const secteurs = SECTEUR_OPTIONS.slice(0).map((secteur) => {
        return {
          ...secteur,
          value:
            initialFilters.secteur.length === 0 || initialFilters.secteur.findIndex((el) => el === secteur.key) !== -1,
        };
      });

      const fonctions = FONCTION_OPTIONS.slice(0).map((fonction) => {
        return {
          ...fonction,
          value:
            initialFilters.fonction.length === 0 ||
            initialFilters.fonction.findIndex((el) => el === fonction.key) !== -1,
        };
      });

      const categoriesEntreprise = CATEGORIE_ENTREPRISE_OPTIONS.slice(0).map((categorie) => {
        return {
          ...categorie,
          value:
            initialFilters.categorieEntreprise.length === 0 ||
            initialFilters.categorieEntreprise.findIndex((el) => el === categorie.key) !== -1,
        };
      });

      setMatrixData((prev) => {
        return {
          ...prev,
          display: initialFilters.display,
          piliers: { activated: isActivated(piliers), options: piliers },
          sujets: { activated: isActivated(sujets), options: sujets },
          categoriePartiePrenante: { activated: isActivated(categoriesPP), options: categoriesPP },
          genre: { activated: isActivated(genres), options: genres },
          age: { activated: isActivated(ages), options: ages },
          csp: { activated: isActivated(csp), options: csp },
          secteur: { activated: isActivated(secteurs), options: secteurs },
          fonction: { activated: isActivated(fonctions), options: fonctions },
          categorieEntreprise: {
            activated: isActivated(categoriesEntreprise),
            options: categoriesEntreprise,
          },
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projetId, initialFilters, piliers, categoriesPartiesPrenantes, intl, sujets],
  );
  const [validateButtonEnable, setValidateButtonEnable] = useState(0);

  const isActivated = (element: SwitchFormFieldOption[]) => {
    let active = false;
    element.forEach((item) => {
      if (item.value === false) {
        active = true;
      }
    });
    return active;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDrawerOpen(false);
    props.onChange({
      filters: {
        display: matrixData.display === Display.SOUS_SUJET ? Display.SOUS_SUJET : Display.SUJET,
        piliers: matrixData.piliers.options.reduce(function (filtered: string[], el) {
          if (el.value) {
            filtered.push(el.key);
          }
          return filtered;
        }, []),
        sujets: matrixData.sujets.options.reduce(function (filtered: string[], el) {
          if (el.value) {
            filtered.push(el.key);
          }
          return filtered;
        }, []),
        categoriePartiePrenante: matrixData.categoriePartiePrenante.options.reduce(function (filtered: string[], el) {
          if (el.value) {
            filtered.push(el.key);
          }
          return filtered;
        }, []),
        genre: matrixData.genre.options.reduce(function (filtered: string[], el) {
          if (el.value) {
            filtered.push(el.key);
          }
          return filtered;
        }, []),
        age: matrixData.age.options.reduce(function (filtered: string[], el) {
          if (el.value) {
            filtered.push(el.key);
          }
          return filtered;
        }, []),
        csp: matrixData.csp.options.reduce(function (filtered: string[], el) {
          if (el.value) {
            filtered.push(el.key);
          }
          return filtered;
        }, []),
        secteur: matrixData.secteur.options.reduce(function (filtered: string[], el) {
          if (el.value) {
            filtered.push(el.key);
          }
          return filtered;
        }, []),
        fonction: matrixData.fonction.options.reduce(function (filtered: string[], el) {
          if (el.value) {
            filtered.push(el.key);
          }
          return filtered;
        }, []),
        categorieEntreprise: matrixData.categorieEntreprise.options.reduce(function (filtered: string[], el) {
          if (el.value) {
            filtered.push(el.key);
          }
          return filtered;
        }, []),
      },
    });

    // Highlight activated filters
    setMatrixData((prev) => ({
      ...prev,
      piliers: { options: prev.piliers.options, activated: isActivated(prev.piliers.options) },
      sujets: { options: prev.sujets.options, activated: isActivated(prev.sujets.options) },
      categoriePartiePrenante: {
        options: prev.categoriePartiePrenante.options,
        activated: isActivated(prev.categoriePartiePrenante.options),
      },
      genre: { options: prev.genre.options, activated: isActivated(prev.genre.options) },
      age: { options: prev.age.options, activated: isActivated(prev.age.options) },
      csp: { options: prev.csp.options, activated: isActivated(prev.csp.options) },
      secteur: { options: prev.secteur.options, activated: isActivated(prev.secteur.options) },
      fonction: { options: prev.fonction.options, activated: isActivated(prev.fonction.options) },
      categorieEntreprise: {
        options: prev.categorieEntreprise.options,
        activated: isActivated(prev.categorieEntreprise.options),
      },
    }));

    // Disable "Valider" button
    setValidateButtonEnable(0);

    // Collapse all filter panels
    setExpandedPanel(false);
  };

  const onChangeRadioFormField = useCallback<FormFieldPropsEventHandler>(function (event) {
    const { name, value } = event;
    setValidateButtonEnable(1);
    setMatrixData((prev) => {
      if (!(name in prev)) {
        throw new Error(`Key ${name}`);
      }
      return {
        ...prev,
        [name]: value,
      };
    });
  }, []);

  const onChangeSwitchFormField = useCallback<SwitchFormFieldPropsEventHandler>(function (event) {
    const { id, checked } = event;
    setValidateButtonEnable(1);
    setMatrixData((prevData) => ({
      ...prevData,
      piliers: {
        activated: prevData.piliers.activated,
        options: prevData.piliers.options.map((el) => getOptions(el, id, checked)),
      },
      sujets: {
        activated: prevData.sujets.activated,
        options: prevData.sujets.options.map((el) => getOptions(el, id, checked)),
      },
      categoriePartiePrenante: {
        activated: prevData.categoriePartiePrenante.activated,
        options: prevData.categoriePartiePrenante.options.map((el) => getOptions(el, id, checked)),
      },
      genre: {
        activated: prevData.genre.activated,
        options: prevData.genre.options.map((el) => getOptions(el, id, checked)),
      },
      age: {
        activated: prevData.age.activated,
        options: prevData.age.options.map((el) => getOptions(el, id, checked)),
      },
      csp: {
        activated: prevData.csp.activated,
        options: prevData.csp.options.map((el) => getOptions(el, id, checked)),
      },
      secteur: {
        activated: prevData.secteur.activated,
        options: prevData.secteur.options.map((el) => getOptions(el, id, checked)),
      },
      fonction: {
        activated: prevData.fonction.activated,
        options: prevData.fonction.options.map((el) => getOptions(el, id, checked)),
      },
      categorieEntreprise: {
        activated: prevData.categorieEntreprise.activated,
        options: prevData.categorieEntreprise.options.map((el) => getOptions(el, id, checked)),
      },
    }));
  }, []);

  const onCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const reset = () => {
    setMatrixData(initialMatrixData);
    props.onChange({
      filters: {
        display: Display.SUJET,
        piliers: [],
        sujets: [],
        categoriePartiePrenante: [],
        genre: [],
        age: [],
        csp: [],
        secteur: [],
        fonction: [],
        categorieEntreprise: [],
      },
    });

    // Disable "Valider" button
    setValidateButtonEnable(0);

    // Collapse all filter panels
    setExpandedPanel(false);
  };

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={onCloseDrawer}>
      <Stack sx={{ alignItems: 'center' }} overflow="hidden" height="100vh" p={4}>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box />
          <Typography variant="h2">
            <FormattedMessage id="matrix.filter.title" />
          </Typography>
          <IconButton
            aria-label={intl.formatMessage({ id: 'matrix.filter.close' })}
            onClick={() => setDrawerOpen(false)}
            color="primary"
            size="large"
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
        <Stack component="form" onSubmit={handleSubmit} direction="column" spacing={3} textAlign="start">
          <RadioFormField
            labelId=""
            name="display"
            value={matrixData.display}
            onChange={onChangeRadioFormField}
            options={DISPLAY_OPTIONS}
            required
          />
          <Stack
            direction="column"
            spacing={1}
            textAlign="start"
            sx={{ padding: 1 }}
            overflow="auto"
            height={`calc(100vh - 14rem)`}
          >
            <AccordionFilterElement
              panel_name="piliers"
              panel_key="panel-piliers"
              panel_header_label_id="matrix.filter.piliers"
              switch_options={matrixData.piliers.options}
              onChange={onChangeSwitchFormField}
              expandedPanel={expandedPanel}
              setExpandedPanel={setExpandedPanel}
              selectedPanel={matrixData.piliers.activated}
            />

            <AccordionFilterElement
              panel_name="sujets"
              panel_key="panel-sujets"
              panel_header_label_id="matrix.filter.sujets"
              switch_options={matrixData.sujets.options}
              onChange={onChangeSwitchFormField}
              expandedPanel={expandedPanel}
              setExpandedPanel={setExpandedPanel}
              selectedPanel={matrixData.sujets.activated}
            />

            <AccordionFilterElement
              panel_name="categoriePP"
              panel_key="panel-categoriePP"
              panel_header_label_id="matrix.filter.categoriePartiePrenante"
              switch_options={matrixData.categoriePartiePrenante.options}
              onChange={onChangeSwitchFormField}
              expandedPanel={expandedPanel}
              setExpandedPanel={setExpandedPanel}
              selectedPanel={matrixData.categoriePartiePrenante.activated}
            />
            {utilisateur?.role === UtilisateurRole.TENZING && (
              <>
                <AccordionFilterElement
                  panel_name="genre"
                  panel_key="panel-genre"
                  panel_header_label_id="matrix.filter.genre"
                  switch_options={matrixData.genre.options}
                  onChange={onChangeSwitchFormField}
                  expandedPanel={expandedPanel}
                  setExpandedPanel={setExpandedPanel}
                  selectedPanel={matrixData.genre.activated}
                />

                <AccordionFilterElement
                  panel_name="age"
                  panel_key="panel-age"
                  panel_header_label_id="matrix.filter.age"
                  switch_options={matrixData.age.options}
                  onChange={onChangeSwitchFormField}
                  expandedPanel={expandedPanel}
                  setExpandedPanel={setExpandedPanel}
                  selectedPanel={matrixData.age.activated}
                />

                <AccordionFilterElement
                  panel_name="csp"
                  panel_key="panel-csp"
                  panel_header_label_id="matrix.filter.csp"
                  switch_options={matrixData.csp.options}
                  onChange={onChangeSwitchFormField}
                  expandedPanel={expandedPanel}
                  setExpandedPanel={setExpandedPanel}
                  selectedPanel={matrixData.csp.activated}
                />

                <AccordionFilterElement
                  panel_name="secteur"
                  panel_key="panel-secteur"
                  panel_header_label_id="matrix.filter.secteur"
                  switch_options={matrixData.secteur.options}
                  onChange={onChangeSwitchFormField}
                  expandedPanel={expandedPanel}
                  setExpandedPanel={setExpandedPanel}
                  selectedPanel={matrixData.secteur.activated}
                />

                <AccordionFilterElement
                  panel_name="fonction"
                  panel_key="panel-fonction"
                  panel_header_label_id="matrix.filter.fonction"
                  switch_options={matrixData.fonction.options}
                  onChange={onChangeSwitchFormField}
                  expandedPanel={expandedPanel}
                  setExpandedPanel={setExpandedPanel}
                  selectedPanel={matrixData.fonction.activated}
                />

                <AccordionFilterElement
                  panel_name="categorieEntreprise"
                  panel_key="panel-categorieEntreprise"
                  panel_header_label_id="matrix.filter.categorieEntreprise"
                  switch_options={matrixData.categorieEntreprise.options}
                  onChange={onChangeSwitchFormField}
                  expandedPanel={expandedPanel}
                  setExpandedPanel={setExpandedPanel}
                  selectedPanel={matrixData.categorieEntreprise.activated}
                />
              </>
            )}
          </Stack>
          <Box display="flex" justifyContent="space-between">
            <Button variant="outlined" onClick={reset} sx={{ mr: 2 }}>
              <FormattedMessage id="matrix.filter.reset" />
            </Button>
            <Button variant="contained" type="submit" disabled={validateButtonEnable === 0}>
              <FormattedMessage id="matrix.filter.validate" />
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Drawer>
  );

  function getOptions(el: SwitchFormFieldOption, id: string, checked: boolean): SwitchFormFieldOption {
    return el.key === id ? { ...el, value: checked } : el;
  }
}
