import { Stack } from '@mui/material';
import { ParcoursPartiePrenanteDto } from '@shared/src/api/parcours-partie-prenante/dto/parcours-partie-prenante.dto';
import { ProfileComponent } from '@shared/src/components/questionnaire/Content/ProfileComponent';
import { useSujets } from '@shared/src/hooks/useSujets';
import { useParams } from 'react-router-dom';

export type ProfilingPageProps = {
  parcours: ParcoursPartiePrenanteDto | null;
};

export function ProfilingPage(props: ProfilingPageProps) {
  const { parcours } = props;
  const { organisationId, projetId, partiePrenanteId } = useParams();
  const { sujets } = useSujets(parcours?.id);
  return (
    <Stack>
      <ProfileComponent
        parcours={parcours}
        stepperLevel={(sujets?.length || 1) + 1}
        baseUrl={`/${organisationId}/projets/${projetId}/stakeholders/listing/${partiePrenanteId}`}
      />
    </Stack>
  );
}
