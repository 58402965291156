import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { Alert, Snackbar, Tooltip, Typography } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid';
import { UtilisateurDto } from '@shared/src/api/utilisateurs/dto/utilisateur.dto';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { projetClientService } from '@shared/src/services/ProjetClientService';
import { utilisateursService } from '@shared/src/services/UtilisateursService';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { CustomColumnMenu } from '../../components/Stakeholders/Grid/CustomColumnMenu';
import { CustomGridLabels } from '../../components/Stakeholders/Grid/CustomGridLabels';
import { CustomPagination } from '../../components/Stakeholders/Grid/CustomPagination';
import { CustomToolbar } from '../../components/Stakeholders/Grid/CustomToolbar';
import DeletionAlert from '../Stakeholders/Grid/DeletionAlert';

export type AdminEditionType = UtilisateurDto & { projetId: string; isNew?: boolean };

export const TableAdmin: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { projetId } = useParams();
  const intl = useIntl();

  const [rows, setRows] = useState<AdminEditionType[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState<{
    open: boolean;
    adminToDelete?: { id: GridRowId | null; nom: string; prenom: string };
  }>({
    open: false,
    adminToDelete: {
      id: null,
      nom: '',
      prenom: '',
    },
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(
    function () {
      if (!projetId) {
        setRows([]);
        return;
      }

      const abortController = new AbortController();
      projetClientService
        .findUtilisateursByProjetId(projetId, {
          signal: abortController.signal,
        })
        .then(function (response) {
          if ('statusCode' in response) {
            return Promise.reject(response);
          }
          const adminList: AdminEditionType[] = response.data;
          setRows(
            adminList.map((el) => {
              return { ...el, projetId, isNew: false };
            }),
          );
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          console.error(error);
        });

      return function () {
        abortController.abort();
      };
    },
    [projetId],
  );

  const columns: GridColDef[] = [
    {
      field: 'nom',
      headerName: intl.formatMessage({ id: 'dataGrid.header.name' }),
      flex: 12,
      minWidth: 100,
      editable: true,
    },
    {
      field: 'prenom',
      headerName: intl.formatMessage({ id: 'dataGrid.header.surname' }),
      flex: 12,
      minWidth: 100,
      editable: true,
    },
    {
      field: 'email',
      headerName: intl.formatMessage({ id: 'dataGrid.header.email' }),
      type: 'email',
      flex: 35,
      minWidth: 300,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: intl.formatMessage({ id: 'dataGrid.header.actions' }),
      flex: 10,
      minWidth: 80,
      maxWidth: 120,
      cellClassName: 'actions',
      hideable: false,
      getActions: ({ id }) => {
        return [
          <Tooltip title={intl.formatMessage({ id: 'dataGrid.deleteButton' })}>
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label={intl.formatMessage({ id: 'dataGrid.deleteButton' })}
              onClick={handleDeleteClick(id)}
              sx={{
                color: 'primary.main',
              }}
            />
          </Tooltip>,
        ];
      },
    },
  ];

  const handleAddClick = () => {
    if (rows.findIndex((el) => el.id === 'temporary_id') === -1 && projetId) {
      const id = 'temporary_id';
      const newUser: AdminEditionType = {
        id: id,
        nom: '',
        prenom: '',
        email: '',
        role: UtilisateurRole.CLIENT,
        etreRecontacte: false,
        cguAccepte: false,
        projetId,
        isNew: true,
      };

      setRows((oldRows) => [newUser, ...oldRows]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'nom' },
      }));
    }
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const adminToDelete = rows.find((row) => row.id === id);
    if (adminToDelete) {
      const nom = adminToDelete.nom;
      const prenom = adminToDelete.prenom;
      setDeleteAlertOpen({ open: true, adminToDelete: { id, nom, prenom } });
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const createNewAdmin = async (updatedRow: AdminEditionType) => {
    const user: {
      id?: string;
      nom: string;
      prenom: string;
      email: string;
      role: UtilisateurRole;
      projetId?: string;
    } = {
      nom: updatedRow.nom,
      prenom: updatedRow.prenom,
      email: updatedRow.email,
      role: UtilisateurRole.CLIENT,
      projetId,
    };
    // On cherche si le mail existe déjà
    await utilisateursService.findOneByEmail(user.email).then((response) => {
      if ('data' in response && response.data?.id) {
        // Edition d'un utilisateur existant
        const userId = response.data.id;
        utilisateursService.update(userId, updatedRow).then((response) => {
          if ('data' in response) {
            setRows(rows.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
          } else {
            // Throw an error if the request is not successful
            setRowModesModel({ ...rowModesModel, [updatedRow.id]: { mode: GridRowModes.Edit } });
            console.error(response);
            setOpenErrorSnackbar(true);
          }
        });
      } else {
        // Création de l'utilisateur
        utilisateursService.create(user).then((response) => {
          if ('statusCode' in response) {
            // Throw an error if the request is not successful
            const id = 'temporary_id';
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
            console.error(response);
            setOpenErrorSnackbar(true);
          } else {
            setRows(rows.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
          }
        });
      }
    });
  };

  const processRowUpdate = (newRow: UtilisateurDto, oldRow: UtilisateurDto) => {
    if (projetId) {
      const updatedRow: AdminEditionType = {
        ...newRow,
        projetId,
      };

      // if all user data are filled
      if (updatedRow.nom.length > 0 && updatedRow.prenom.length > 0 && updatedRow.email.length > 0) {
        if (updatedRow.isNew) {
          // if this is a new user
          createNewAdmin(updatedRow);
        }
      } else {
        throw new Error('Wrong information about user');
      }
    }
    return newRow;
  };

  const processRowDelete = (id: GridRowId) => {
    if (projetId)
      projetClientService
        .remove(id as string, projetId)
        .then(() => setRows(rows.filter((row) => row.id !== id)))
        .catch((e) => console.error(e));
  };

  return (
    <>
      <DeletionAlert
        alertOpen={deleteAlertOpen}
        setAlertOpen={setDeleteAlertOpen}
        deleteElement={processRowDelete}
        title={intl.formatMessage({ id: 'adminList.deletion.alert.title' })}
        contentPartOne={intl.formatMessage({ id: 'adminList.deletion.alert.content.partOne' })}
        contentPartTwo={intl.formatMessage({ id: 'adminList.deletion.alert.content.partTwo' })}
      />
      <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={() => setOpenErrorSnackbar(false)}>
        <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage ?? intl.formatMessage({ id: 'dataGrid.snackbar.updateAdmin.error' })}
        </Alert>
      </Snackbar>

      <Typography variant="h2" sx={{ display: 'flex', mt: '2rem' }}>
        <FormattedMessage id="projet.edit.admin-table" />
      </Typography>

      <DataGrid
        autoHeight
        sx={{ backgroundColor: 'white', borderColor: 'white', borderRadius: '1rem', mt: '2rem' }}
        rows={rows}
        columns={columns}
        loading={isLoading}
        editMode="row"
        onProcessRowUpdateError={() => setOpenErrorSnackbar(true)}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: () =>
            CustomToolbar({
              handleAddClick: handleAddClick,
              disableInviteButton: true,
              disableRemindButton: true,
              setOpenErrorSnackbar,
              setErrorMessage,
              setIsLoading,
            }),
          pagination: CustomPagination,
          columnMenu: CustomColumnMenu,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        pageSizeOptions={[5, 25, 50, 100]}
        localeText={CustomGridLabels()}
      />
    </>
  );
};
