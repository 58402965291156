import WarningIcon from '@mui/icons-material/Warning';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export type CloseAlertProps = {
  alertOpen: boolean;
  title: string;
  content: string;
  setAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeProjet: () => void;
};

export default function CloseAlert(props: CloseAlertProps) {
  const { alertOpen, title, content, setAlertOpen, closeProjet } = props;

  const handleClose = (validation: boolean) => {
    setAlertOpen(false);
    if (validation) {
      closeProjet();
    }
  };

  return (
    <Dialog open={alertOpen} onClose={() => handleClose(false)}>
      <DialogTitle textAlign="center">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: 'primary.main', fontWeight: 600, mb: '2rem' }}>
          <Grid container>
            <Grid item xs={2} sm={1} sx={{ display: 'flex', alignItems: 'center' }}>
              <WarningIcon />
            </Grid>
            <Grid item xs={10} sm={11}>
              {content}
            </Grid>
          </Grid>
        </DialogContentText>
        <DialogContentText>
          <FormattedMessage id="projet.closeProjet.alert.content" />
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button style={{ minWidth: 'auto' }} onClick={() => handleClose(false)} autoFocus>
          <FormattedMessage id="all.no" />
        </Button>
        <Button style={{ minWidth: 'auto' }} onClick={() => handleClose(true)}>
          <FormattedMessage id="all.yes" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
