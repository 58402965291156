import { GlobalSettingsPage } from '../../pages/global-settings/GlobalSettings';
import { QuestionsTab } from './QuestionsTab';

export function QuestionsGlobal() {
  return (
    <GlobalSettingsPage>
      <QuestionsTab />
    </GlobalSettingsPage>
  );
}
