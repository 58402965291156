import { QuestionnairePage } from '../../pages/projets/QuestionnairePage';
import { SubsubjectsTab } from './SubsubjectsTab';

export function SubsubjectsPage() {
  return (
    <QuestionnairePage>
      <SubsubjectsTab />
    </QuestionnairePage>
  );
}
