import { Avatar, Grid } from '@mui/material';
import { SujetsWithNbRespondents } from '@shared/src/api/reponse-partie-prenante/dto/reponse-partie-prenante.dto';

export type FakeLogoTenzingProps = {
  sujetsWithNbRespondents: SujetsWithNbRespondents[];
};

export function FakeLogoTenzing(props: FakeLogoTenzingProps) {
  const { sujetsWithNbRespondents } = props;
  return (
    <Grid item container xs={12} spacing={'15px'} sx={{ display: { xs: 'none', md: 'flex' } }}>
      {sujetsWithNbRespondents.map((element) => (
        <Grid item xs={4} key={element.sujet_id}>
          {element.nbRespondents > 0 && (
            <Avatar
              sx={{ width: { xs: '33px', xl: '50px' }, height: { xs: '33px', xl: '50px' }, bgcolor: 'primary.main' }}
            >
              {' '}
            </Avatar>
          )}
          {element.nbRespondents === 0 && (
            <Avatar sx={{ width: { xs: '33px', xl: '50px' }, height: { xs: '33px', xl: '50px' }, bgcolor: '#4B4B4B' }}>
              {' '}
            </Avatar>
          )}
        </Grid>
      ))}
    </Grid>
  );
}
