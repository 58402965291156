import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';

export const GlobalSettingsPage: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const tabPath = pathname.split('/').pop();

  return (
    <Layout>
      <Box
        sx={{
          display: 'flex',
          mx: '2rem',
        }}
      >
        <Typography alignSelf="center" variant="h1">
          <FormattedMessage id="global-settings.title" />
        </Typography>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabPath}
          variant="scrollable"
          scrollButtons="auto"
          aria-label={intl.formatMessage({ id: 'questionnaire.tabs' })}
        >
          <Tab value="sujet" label={intl.formatMessage({ id: 'questionnaire.subjects.title' })} href="sujet" />
          <Tab value="question" label={intl.formatMessage({ id: 'questionnaire.questions.title' })} href="question" />
          <Tab
            value="sous-sujet"
            label={intl.formatMessage({ id: 'questionnaire.subsubjects.title' })}
            href="sous-sujet"
          />
          <Tab value="label" label={intl.formatMessage({ id: 'stakeholders.label.title' })} href="label" />
          <Tab value="matching" label={intl.formatMessage({ id: 'stakeholders.matching.title' })} href="matching" />
          <Tab
            value="explication"
            label={intl.formatMessage({ id: 'questionnaire.explication.title' })}
            href="explication"
          />
        </Tabs>
      </Box>
      <Container role="tabpanel" sx={{ mt: '2rem' }}>
        {children}
      </Container>
    </Layout>
  );
};
