import { Stack } from '@mui/material';
import { ProfileComponent } from '@shared/src/components/questionnaire/Content/ProfileComponent';
import { useSujetsByCategory } from '@shared/src/hooks/useSujetsByCategory';
import { useParams } from 'react-router-dom';

export function ProfilingPage() {
  const { organisationId, projetId, categorieId } = useParams();
  const { sujets, isLoading } = useSujetsByCategory(projetId, categorieId);

  if (isLoading) {
    return null;
  }
  if (!sujets?.length) {
    return null;
  }
  if (!projetId || !categorieId) {
    return null;
  }

  return (
    <Stack marginTop="1rem">
      <ProfileComponent
        parcours={null}
        stepperLevel={(sujets?.length || 1) + 1}
        baseUrl={`/${organisationId}/projets/${projetId}/questionnaire/preview/${categorieId}`}
      />
    </Stack>
  );
}
