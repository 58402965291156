import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GridRowId } from '@mui/x-data-grid';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export type DeletionAlertProps = {
  alertOpen: {
    open: boolean;
    ppToDelete?: { id: GridRowId | null; nom: string; prenom: string };
    adminToDelete?: { id: GridRowId | null; nom: string; prenom: string };
  };
  setAlertOpen: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      ppToDelete?: { id: GridRowId | null; nom: string; prenom: string };
      adminToDelete?: { id: GridRowId | null; nom: string; prenom: string };
    }>
  >;
  deleteElement: (id: GridRowId) => void;
  title: string;
  contentPartOne: string;
  contentPartTwo: string;
};

export default function DeletionAlert(props: DeletionAlertProps) {
  const { alertOpen, setAlertOpen, deleteElement, title, contentPartOne, contentPartTwo } = props;

  const handleClose = (validation: boolean) => {
    setAlertOpen({
      open: false,
      ppToDelete: { id: null, nom: '', prenom: '' },
      adminToDelete: { id: null, nom: '', prenom: '' },
    });
    if (validation && alertOpen.ppToDelete?.id) {
      deleteElement(alertOpen.ppToDelete.id);
    }
    if (validation && alertOpen.adminToDelete?.id) {
      deleteElement(alertOpen.adminToDelete.id);
    }
  };

  return (
    <Dialog open={alertOpen.open} onClose={() => handleClose(false)}>
      <DialogTitle textAlign="center">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentPartOne}</DialogContentText>
        {alertOpen.ppToDelete && (
          <DialogContentText>
            {alertOpen.ppToDelete.prenom} {alertOpen.ppToDelete.nom}
          </DialogContentText>
        )}
        {alertOpen.adminToDelete && (
          <DialogContentText>
            {alertOpen.adminToDelete.prenom} {alertOpen.adminToDelete.nom}
          </DialogContentText>
        )}
        <DialogContentText>{contentPartTwo}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button style={{ minWidth: 'auto' }} onClick={() => handleClose(false)} id="deletion-alert-button-no" autoFocus>
          <FormattedMessage id="all.no" />
        </Button>
        <Button style={{ minWidth: 'auto' }} onClick={() => handleClose(true)} id="deletion-alert-button-yes">
          <FormattedMessage id="all.yes" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
