import SharedTranslations, { IntlMessageMapping } from '@shared/src/translations';
import EnTranslations from './en.json';
import FrTranslations from './fr.json';

const messages: IntlMessageMapping = {
  fr: {
    ...SharedTranslations.fr,
    ...FrTranslations,
  },
  en: {
    ...SharedTranslations.en,
    ...EnTranslations,
  },
};

export default messages;
